import * as CONST from './checkoutPaymentConstants';

const initialState = {
  paymentInfo: {
    hasPaymentToken: false
  }
};

const CheckoutPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONST.SET_PAYMENT_INFO:
      return {
        ...state,
        paymentInfo: action.cartData.paymentInfo
      };
    case CONST.SET_IFRAME_COMPLETED:
      return {
        ...state,
        iframe: action.iframe
      };
    default:
      return state;
  }
};

export default CheckoutPaymentReducer;
