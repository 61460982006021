import { omit, includes } from 'lodash';

const billingFieldNames = [
  'titleCode',
  'firstName',
  'secondFirstName',
  'lastName',
  'secondLastName',
  'line1',
  'line2',
  'city',
  'region.isocode',
  'postalCode',
  'phone',
  'country',
  'cellPhoneNumber',
  'unitNumber',
  'colonia'
];

const namespaceMapper = (namespace, namespacedFields, formData) =>
  Object.keys(formData).reduce((prev, current) => {
    if (includes(namespacedFields, current)) {
      return {
        ...prev,
        [`${namespace}.${current}`]: formData[current]
      };
    }

    return {
      ...prev,
      [current]: formData[current]
    };
  }, {});

export const mapToBilling = formData =>
  namespaceMapper('billingAddress', billingFieldNames, formData);

export const removeSensitiveInfo = formData =>
  omit(formData, ['securityNumber', 'cardType', 'cardNumber', 'expiryDate', 'nameOnCard']);
