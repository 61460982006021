import * as CONST from './passwordTextInputConstants';

const initialState = {};

const PasswordTextInputReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONST.TOGGLE_PASSWORD_TYPE:
      return {
        ...state,
        [action.typeKey]: action.isPassword
      };
    default:
      return state;
  }
};

export default PasswordTextInputReducer;
