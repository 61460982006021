import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import PasswordAndConfirmation from 'app/formComponents/passwordAndConfirmationFormGroup/passwordAndConfirmationFormGroup';

const { registrationValidation } = window.inlineCheckout;

class OxxoPayPayment extends Component {
  componentDidMount() {
    const { oxxoPayDetailsMountTrigger, selectedDeliveryMode } = this.props;
    oxxoPayDetailsMountTrigger(selectedDeliveryMode);
  }

  render() {
    const { getOxxoPayInfo, onSubmitPayment, displayOxxoPay, isLoggedIn, formGroups } = this.props;
    const handlersAndValidation = {
      handleValidForm: formGroups.resolve,
      handleInvalidForm: formGroups.reject,
      validation: registrationValidation
    };

    return (
      <div className="checkout-section__oxxopay">
        <p className="checkout-section__oxxopay-msg">
          {
            displayOxxoPay
              ? <FormattedHTMLMessage id="checkout.billing.oxxoPay.message" description="Oxxo Pay message" />
              : <div>
                <FormattedHTMLMessage id="checkout.billing.oxxoPay.message.invalid" description="Oxxo Pay message" />
                {' '}
                <span className="lato">
                    <FormattedHTMLMessage id="checkout.billing.oxxoPay.message.invalid.amount"
                                          description="Oxxo Pay message" />
                  </span>
              </div>
          }
        </p>
        {
          !isLoggedIn &&
          <PasswordAndConfirmation
            formName={formGroups.getName('passwordAndConfirmation')}
            formGroups={formGroups}
            {...handlersAndValidation}
          />
        }
        <button className="button center" disabled={!displayOxxoPay}
                onClick={(e) => onSubmitPayment(e, getOxxoPayInfo)}>
          <FormattedMessage id="checkout.billing.oxxoPay.continue" description="Change" />
        </button>
      </div>
    );
  }
}

OxxoPayPayment.propTypes = {
  getOxxoPayInfo: PropTypes.func.isRequired,
  onSubmitPayment: PropTypes.func.isRequired,
  oxxoPayDetailsMountTrigger: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  formGroups: PropTypes.object.isRequired
};

OxxoPayPayment.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default OxxoPayPayment;
