import { CASHONDELIVERY_DISPLAY_URL, REQUEST_CASHONDELIVERY_DATA } from '../../checkoutConstants';
import { setSavedPaymentCards } from '../checkoutPayment/checkoutPaymentActions';
import { ajaxRequest } from 'app/utils/helpers';

const { cashOnDeliveryPaymentInfoUrl, billingCashOnDeliveryErrorUrl } = window.inlineCheckout;

export const getCashOnDeliveryInfo = () => dispatch => {
  ajaxRequest('GET', cashOnDeliveryPaymentInfoUrl)
    .then(
      (response) => {
        dispatch(setSavedPaymentCards(response.cart));
      },
      () => {
        window.location.href = billingCashOnDeliveryErrorUrl;
      }
    );
};

export const getDisplayCashOnDelivery = () => dispatch => {
  ajaxRequest('GET', CASHONDELIVERY_DISPLAY_URL)
      .then(
          (response) => {
            dispatch({
              type: REQUEST_CASHONDELIVERY_DATA,
              response: response
            });
          },
          () => {
            window.location.href = billingCashOnDeliveryErrorUrl;
          }
      );
}
