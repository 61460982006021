import { connect } from 'react-redux';
import AplazoPayment from './aplazoPayment';
import { getAplazoInfo } from './aplazoPaymentActions';
import { APLAZO_PAYMENT_MOUNT } from './aplazoConstants';
import { PAYMENT_METHOD } from '../checkoutPayment/checkoutPaymentConstants';

const mapStateToProps = (state) => ({
  MemberIdLookup: state.MemberIdLookup,
  selectedDeliveryMode: state.Global.cartData.deliveryMode.name,
  paymentType: PAYMENT_METHOD.APLAZO
});

const mapDispatchToProps = (dispatch) => ({
  getAplazoInfo() {
    dispatch(getAplazoInfo());
  },
  aplazoDetailsMountTrigger(deliveryMethod) {
    dispatch({ type: APLAZO_PAYMENT_MOUNT, deliveryMethod });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AplazoPayment);