import React, { Component } from 'react';
 import PropTypes from 'prop-types';
 import { FormattedMessage } from 'react-intl';
 import CardDetails from '../cardDetails/cardDetailsContainer';
 import checkoutPaymentHOC from './checkoutPaymentHOC';
 import checkoutPaymentContainer from './checkoutPaymentContainer';
 import PasswordAndConfirmation from 'app/formComponents/passwordAndConfirmationFormGroup/passwordAndConfirmationFormGroup';
 import PlaceOrderIFrame from '../checkoutReview/orderSummary/placeOrder/placeOrderIFrame/placeOrderIFrame';
 import CheckoutAddressForm from '../../../formComponents/addressForm/checkoutAddressForm';
 import GuestAddressForm from '../../../formComponents/guestAddressForm/guestAddressForm';
 import PaymentInfoContainer from '../../../account/components/paymentInfo/paymentInfoContainer';
 import { connect } from 'react-redux';
 import store from '../../checkoutStore';
 import Guest from '../guest/guestContainer';

 const { registrationValidation, enableUsingSavedCards } = window.inlineCheckout;

 class CheckoutPayment extends Component {

   constructor(props) {
     super(props);

     this.state = {
       inputsInUse: new Set()
     };

     this.isInputInUse = this.isInputInUse.bind(this);
     this.inputInUseHandler = this.inputInUseHandler.bind(this);
   }

   isInputInUse(inputName) {
     return this.state.inputsInUse.has(inputName)
   }

   inputInUseHandler(event, inputName) {
     event.preventDefault();

     const {
       inputsInUse
     } = this.state;

     if (event.target.value.length > 0) {
       inputsInUse.add(inputName);
     } else {
       inputsInUse.delete(inputName);
     }
     this.setState({
       inputsInUse
     });
   }


   render() {
     const {
       onSubmit,
       disableSubmit,
       formGroups,
       billingAddressFormGroups,
       deliveryAddressFormGroups,
       isModalOpen,
       showPostcodeValidationForm,
       showPostcodeTryAnotherMsg,
       showPostcodeSelectAddress,
       postcodeFormGroups,
       togglePostcodeValidation,
       onPostcodeSubmit,
       incorrectFormData,
       paymentInfoDetails,
       gotSavedCard,
       isCapturingNewCard,
       addNewCard,
       isNewBillingAddress,
       toggleUseBillingAddress,
       iFrameRefCallback,
       iFrameRefCallbackSteps,
       renderModal,
       isLoggedIn,
       isPriceOverTwoHundred,
       setIframeLoadedCompleted,
       isGuest
     } = this.props;

     const handlersAndValidation = {
       handleValidForm: formGroups.resolve,
       handleInvalidForm: formGroups.reject,
       validation: registrationValidation
     };

     async function onSubmit2(e) {
       await onSubmit(e);
     }

     const iframe = document.querySelector('#cardinal_collection_iframe');
     if(iframe){
         iframe.onload = () => {
            const { setIframeLoadedCompleted } = this.props;
            setIframeLoadedCompleted(true);
         }
     }

    window.addEventListener("message", function(event) {
      if (event.origin === "https://centinelapistag.cardinalcommerce.com") {
        console.log(event.data);
      }
    }, false);

     return (
       <div className="payment-form">
         <form id="checkoutPaymentForm" autoComplete="on" onSubmit={onSubmit} noValidate name="checkoutPaymentForm">
           {
             (isLoggedIn || isGuest) &&
            <div>
            {
              enableUsingSavedCards && (gotSavedCard && !isCapturingNewCard) ? (
                <div className="account-form-container account-form-container-previus-payment">
                  <PaymentInfoContainer allowDelete={false} titleMessage={<FormattedMessage id="account.previus.paymentInfo.cardTitle.label" /> }/>
                  <div className="columns small-4">
                    <button
                      id="change-payment-method"
                      className="button button--primary right"
                      onClick={addNewCard}
                    >
                      <FormattedMessage id="checkout.review.change.button" description="Change" />
                    </button>
                  </div>
                </div>)
                : <CardDetails
                    formName={formGroups.getName('cardDetails')}
                    usePlaceholder={true}
                    {...handlersAndValidation}
                  />
            }
            </div>
           }
           <hr className="hr-wide" />
           <div className="checkout-section billing-address-details" style={{ display: 'none' }}>
             <h2 className="checkout-section__header checkout-section__header--billing">
              { 
                !isGuest && isLoggedIn &&
                <FormattedMessage id="checkout.billing.card.billing.address.title" />
              }
              {
                isGuest &&
                <FormattedMessage id="checkout.delivery.address.title" />
              }
             </h2>
             { !isGuest && isLoggedIn &&
             <div className="checkout-section__delivery-details row">
               <label htmlFor="billingAddressExisting" className="checkbox-container">
                 <div className="columns large-4 medium-4 small-6">
                   <input
                     className="form-checkbox__radio"
                     name="billingAddress"
                     id="billingAddressExisting"
                     type="radio"
                     value="existing"
                     checked={!isNewBillingAddress}
                     onChange={toggleUseBillingAddress}
                   />
                   <span className="checkmark"/>
                   <span className="form-checkbox__label direction-label">
                     <FormattedMessage id="checkout.billing.card.billing.address.checkbox.label"/>
                   </span>
                 </div>
               </label>
               <label htmlFor="billingAddressNew" className="checkbox-container">
                 <div className="columns large-4 medium-4 small-6" style={{float: "left"}}>
                   <input
                     className="form-checkbox__radio"
                     name="billingAddress"
                     id="billingAddressNew"
                     value="new"
                     checked={isNewBillingAddress}
                     type="radio"
                     onChange={toggleUseBillingAddress}
                   />
                   <span className="checkmark"/>
                   <span className="form-checkbox__label direction-label">
                     <FormattedMessage id="checkout.billing.card.billing.new.address.checkbox.label"/>
                   </span>
                 </div>
               </label>
             </div>
             }
             {
               isNewBillingAddress &&
                 <CheckoutAddressForm
                   formGroups={billingAddressFormGroups}
                   hasReference={false}
                   isInputInUse={this.isInputInUse}
                   inputInUseHandler={this.inputInUseHandler}
                   // Postcode lookup related properties
                   showPostcodeValidationForm={false}
                   showPostcodeTryAnotherMsg={showPostcodeTryAnotherMsg}
                   postcodeFormGroups={postcodeFormGroups}
                 />
             }
             {
              isGuest &&
                <GuestAddressForm
                  formGroups={deliveryAddressFormGroups}
                  hasReference={false}
                  isInputInUse={this.isInputInUse}
                  inputInUseHandler={this.inputInUseHandler}
                  // Postcode lookup related properties
                  showPostcodeValidationForm={false}
                  showPostcodeTryAnotherMsg={showPostcodeTryAnotherMsg}
                  postcodeFormGroups={postcodeFormGroups}
                />
             }
           </div>
           {
             showPostcodeValidationForm
               ? <div>
                 {
                   !showPostcodeSelectAddress &&
                   <button type="button" className="button address-form__btn address-book__btn--find-address"
                     onClick={onPostcodeSubmit}>
                     <FormattedMessage id="security.address.postcode.findAddress.button.label" />
                   </button>
                 }
                 <button id="toggle-postcode" type="button" className="button address-form__link-btn"
                   onClick={togglePostcodeValidation}>
                   <FormattedMessage id="security.address.postcode.notice" />
                 </button>
               </div>
               : <div>
                 {
                  !isLoggedIn && !isGuest &&
                  <div className="row">
                    <div className="columns large-6 medium-6 small-12">
                      <span>Para poder rastrear o hacer una futura compra, por favor crea tu cuenta</span>
                    </div>
                    <div className="columns large-6 medium-6 small-12 register">
                      <a className="btn-sendRegister" href="./login/auth#/register">Regístrarme</a>
                    </div>
                  </div>
                 }
                 {
                  (isLoggedIn || isGuest) &&
                  <button type="button" id="btn-submitPayment" className={isPriceOverTwoHundred && !disableSubmit && ((!this.props.MemberIdLookup.deliveryByReferralChecked || (!this.props.MemberIdLookup.isError && (this.props.MemberIdLookup.memberId !== null && this.props.MemberIdLookup.memberId !== undefined && this.props.MemberIdLookup.memberId !== '')))) ? "auth-form__register-btn-inside" : "auth-form__register-btn-inside-disabled"} disabled={!isPriceOverTwoHundred && disableSubmit}
                  onClick={onSubmit2}
                  >
                    <div className="sending-payment">
                      <FormattedMessage id="checkout.billing.finalize.purchase.message" />
                    </div>
                  </button>
                 }
                 {
                   !isLoggedIn && !isGuest &&
                   <Guest />
                 }
                 {
                   incorrectFormData &&
                   <p className="checkout-notice form-msg-error">
                     <FormattedMessage id="security.address.modal.addressValidation.incorrectFormData.error.label" />
                   </p>
                 }

                 {
                   renderModal &&
                   <div id="3d-secure-modal" className={isModalOpen ? 'display-block' : 'display-none'}>
                     <PlaceOrderIFrame iframeRef={iFrameRefCallback} />
                   </div>
                 }

               </div>
           }
         </form>
       </div>
     )
   }
 };

 CheckoutPayment.propTypes = {
   onSubmit: PropTypes.func.isRequired,
   disableSubmit: PropTypes.bool.isRequired,
   formGroups: PropTypes.object.isRequired,
   billingAddressFormGroups: PropTypes.object.isRequired,
   deliveryAddressFormGroups: PropTypes.object.isRequired,
   selectedRegion: PropTypes.string,
   toggleModal: PropTypes.func.isRequired,
   isModalOpen: PropTypes.bool.isRequired,
   entries: PropTypes.array.isRequired,
   validatedForm: PropTypes.object,
   handleFindAddressClick: PropTypes.func.isRequired,
   showPostcodeValidationForm: PropTypes.bool.isRequired,
   showPostcodeTryAnotherMsg: PropTypes.bool.isRequired,
   showPostcodeSelectAddress: PropTypes.bool.isRequired,
   postcodeEntries: PropTypes.array.isRequired,
   postcodeFormGroups: PropTypes.object.isRequired,
   deliveryPostcodeFormGroups: PropTypes.object.isRequired,
   handlePostcodeContinueClick: PropTypes.func.isRequired,
   togglePostcodeValidation: PropTypes.func.isRequired,
   togglePostcodeSelectAddress: PropTypes.func.isRequired,
   onPostcodeSubmit: PropTypes.func.isRequired,
   incorrectFormData: PropTypes.bool.isRequired,
   gotSavedCard: PropTypes.bool.isRequired,
   isCapturingNewCard: PropTypes.bool.isRequired,
   addNewCard: PropTypes.func.isRequired,
   isNewBillingAddress: PropTypes.bool.isRequired,
   toggleUseBillingAddress: PropTypes.func.isRequired,
   iFrameRefCallback: PropTypes.func.isRequired,
   renderModal: PropTypes.bool.isRequired,
   isLoggedIn: PropTypes.bool.isRequired,
   updateStateCallback: PropTypes.func,
   isPriceOverTwoHundred: PropTypes.bool,
   setIframeLoadedCompleted: PropTypes.func
 };

 export { CheckoutPayment };

 const mapStateToProps = state => {
  return {
   isPriceOverTwoHundred: state.Global.isPriceOverTwoHundred,
   iframeAuthenticationSetups: state.CheckoutPayment.iframe,
  }
 };

 export default connect(mapStateToProps)(checkoutPaymentContainer(checkoutPaymentHOC(CheckoutPayment)));