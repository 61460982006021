import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as PasswordTextInputActions from './passwordTextInputActions';

class PasswordTextInput extends Component {
  constructor(props, context) {
    super();
    this.getPasswordInput = this.getPasswordInput.bind(this);
    this.isPasswordMasked = this.isPasswordMasked.bind(this);
    this.showTxt = context.intl.formatMessage({ id: 'account.password.show' });
    this.hideTxt = context.intl.formatMessage({ id: 'account.password.hide' });
  }

  getPasswordInput() {
    const { children } = this.props;
    return React.cloneElement(React.Children.only(children[0]), {
      type: this.isPasswordMasked() ? 'password' : 'text'
    });
  }

  isPasswordMasked() {
    const {
      passwordKeys,
      typeKey
    } = this.props;

    return passwordKeys[typeKey] === undefined
      ? true
      : passwordKeys[typeKey];
  }

  render() {
    return (
      <div className="form-input__password-wrapper">
        <span
          className="form-input__type-toggle"
          onClick={() => this.props.togglePasswordType(this.props.typeKey, !this.isPasswordMasked())}
        >
          {this.isPasswordMasked() ? this.showTxt : this.hideTxt}
        </span>
        { this.getPasswordInput() }
      </div>
    );
  }
}

PasswordTextInput.defaultProps = {
  typeKey: 'password'
};

PasswordTextInput.propTypes = {
  children: PropTypes.any,
  typeKey: PropTypes.string,
  togglePasswordType: PropTypes.func.isRequired,
  passwordKeys: PropTypes.object
};

PasswordTextInput.contextTypes = {
  intl: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  passwordKeys: {
    ...state.PasswordTextInput
  }
});

export default connect(mapStateToProps, PasswordTextInputActions)(PasswordTextInput);

