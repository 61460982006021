import { ajaxRequest, scrollToPosition, isElementVisible, getElementPosition, getElementHeightNoPadding } from 'app/utils/helpers';
import { isEqual, isEmpty, omit } from 'lodash';
import { FormConstants } from 'react-forms';
import { mapToBilling, removeSensitiveInfo } from './addressForm/addressFormUtils';
import { ANIMATION_TIME } from './formComponentsConstants';
import { PREDEFINED_ADDRESS } from 'app/checkout/checkoutConstants';

const { getAddressData, validateAddressData, validatePostcodeData } = window.inlineGlobalConfig;

const defaultScrollGutter = 20;
export const scrollToError = (containerSelector, extraGutter = defaultScrollGutter) => {
  const container = document.querySelector(containerSelector);
  const firstErrorInput = [].filter.call(container.querySelectorAll('._is-error'), (el) => isElementVisible(el))[0];
  const elementScrollTo = (firstErrorInput != null && firstErrorInput.length > 0) ? firstErrorInput : container;
  const stickyHeader = getElementHeightNoPadding(document.querySelector('.header.header--fixed')) + extraGutter;
  const scrollTop = getElementPosition(elementScrollTo) - stickyHeader;
  scrollToPosition(scrollTop, ANIMATION_TIME);
};

export const scrollToEl = (containerSelector, extraGutter = defaultScrollGutter) => {
  const container = document.querySelector(containerSelector);
  const stickyHeader = getElementHeightNoPadding(document.querySelector('.header.header--fixed')) + extraGutter;
  const scrollTop = getElementPosition(container) - stickyHeader;
  scrollToPosition(scrollTop, ANIMATION_TIME);
};

export const formDataForReplace = address => {
  const formRegion = `${address.country}-${address.region ? address.region : address.country}`;
  const formLine2 = address.line2 === null ? '' : address.line2;

  return {
    line1: address.line1,
    line2: formLine2,
    city: address.town,
    region: formRegion,
    postalCode: address.postalCode,
    country: address.country
  };
};

export const formDataForValidation = address => {
  const formDataIsocode = address['region.isocode'];
  const stripIsocode = formDataIsocode.split('-')[1];

  return {
    line1: address.line1,
    line2: address.line2,
    town: address.city,
    region: stripIsocode,
    postalCode: address.postalCode,
    country: address.country
  };
};

export const formDataForComparison = address => ({
  city: address.city,
  country: address.country,
  line1: address.line1,
  line2: address.line2,
  postalCode: address.postalCode,
  region: isEmpty(address['region.isocode']) ? null : address['region.isocode']
});

export const getValidAddressDetails = (
  stringAddress,
  replaceAddressDetails,
  formGroups,
  updateState
) => {
  if (stringAddress !== null) {
    ajaxRequest('POST', getAddressData, { addressId: stringAddress.id })
      .then((addressDataResponse) => {
        const addressGroupsToReplace = [
          formGroups.getName('countrySelector'),
          formGroups.getName('addressFormGroup'),
          formGroups.getName('addressRegion')
        ];
        const formValuesForReplacement = formDataForReplace(addressDataResponse);
        replaceAddressDetails(addressGroupsToReplace, formValuesForReplacement);

        updateState({
          selectedRegion: formValuesForReplacement.region,
          validatedForm: formValuesForReplacement
        });
      });
  }
};

export const validateNorthAmerica = (
  previouslyValidatedAddress,
  formData,
  modalToggle,
  updateState,
  proceedAddress,
  args = null,
  additionalInfo = null
) => {
  const addressToValidate = formDataForValidation(formData);

  if (!isEqual(formDataForReplace(addressToValidate), previouslyValidatedAddress)) {
    ajaxRequest('POST', validateAddressData, addressToValidate).then(
      (validationResponse) => {
        if (validationResponse.address === null && validationResponse.entries.length > 0) {
          modalToggle();
          updateState({ entries: validationResponse.entries });
        } else if (validationResponse.address === null && validationResponse.entries.length === 0) {
          updateState({ incorrectFormData: true });
        } else if (validationResponse.address && validationResponse.entries.length === 0) {
          const formValuesForReplacement = formDataForReplace(validationResponse.address);

          updateState({
            selectedRegion: formValuesForReplacement.region,
            validatedForm: formValuesForReplacement,
            entries: null
          });

          modalToggle();
        }
      });
  } else if (args) {
    proceedAddress(formData, ...args);
  } else if (additionalInfo === 'prepareForBilling') {
    const adaptedFormData = removeSensitiveInfo(mapToBilling(formData));
    proceedAddress(adaptedFormData, updateState);
  } else {
    proceedAddress(formData, updateState);
  }
};

export const validatePostcode = (formData, updateState) => {
  const postcodeFormLabel = document.querySelector('label[for=postalCode]');
  const postcodeFormField = document.querySelector('input[name=postalCode]');
  const incorrectPostcodeErrorMsg = document.querySelector('.address-form__incorrect-postcode-error');

  const postcodeError = () => {
    postcodeFormLabel.classList.remove(FormConstants.ERROR_INPUT_CLASS_NAME);
    postcodeFormField.classList.remove(FormConstants.ERROR_INPUT_CLASS_NAME);
    incorrectPostcodeErrorMsg.classList.remove('display-block');
  };

  postcodeFormField.removeEventListener('keydown', postcodeError);

  ajaxRequest('POST', validatePostcodeData, formData)
    .then(
      (validationResponse) => {
        if (validationResponse.length > 0 && !isEmpty(validationResponse[0].id)) {
          updateState({
            postcodeEntries: validationResponse,
            showPostcodeSelectAddress: true
          });
        } else if (validationResponse.length === 1 && isEmpty(validationResponse[0].id)) {
          postcodeFormLabel.classList.add(FormConstants.ERROR_INPUT_CLASS_NAME);
          postcodeFormField.classList.add(FormConstants.ERROR_INPUT_CLASS_NAME);
          incorrectPostcodeErrorMsg.classList.add('display-block');
          postcodeFormField.addEventListener('keydown', postcodeError);
        }
      });
};

const handlePredefinedAddress = () => {
  let predefinedAddressSelected = false;
  return (
    selectedDeliveryModeInfo,
    formGroups,
    replaceAddressDetails,
    resetFormData,
    resetPredefinedAddress
  ) => {
    const addressGroupsToModify = [
      formGroups.getName('addressFormGroup'),
      formGroups.getName('addressRegion')
    ];

    if (selectedDeliveryModeInfo && selectedDeliveryModeInfo.type === PREDEFINED_ADDRESS) {
      const predefinedAddressZone = selectedDeliveryModeInfo.address;
      predefinedAddressSelected = true;
      replaceAddressDetails(addressGroupsToModify, omit(formDataForReplace(predefinedAddressZone), ['id']));
      resetPredefinedAddress(predefinedAddressSelected);
    } else if (predefinedAddressSelected) {
      predefinedAddressSelected = false;
      addressGroupsToModify.forEach(group => resetFormData(group));
      resetPredefinedAddress(predefinedAddressSelected);
    }
  };
};

export const replaceAddressWithStaffDiscount = handlePredefinedAddress();
