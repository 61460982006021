import { setSavedPaymentCards } from '../checkoutPayment/checkoutPaymentActions';
import { ajaxRequest } from 'app/utils/helpers';

const { oxxoPayPaymentInfoUrl, billingOxxoPayErrorUrl } = window.inlineCheckout;

export const getOxxoPayInfo = () => dispatch => {
  ajaxRequest('GET', oxxoPayPaymentInfoUrl)
    .then(
      (response) => dispatch(setSavedPaymentCards(response.cart)),
      () => window.location.href = billingOxxoPayErrorUrl
    );
};
