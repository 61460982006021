import { connect } from 'react-redux';
import OxxoPayPayment from './oxxoPayPayment';
import { getOxxoPayInfo } from './oxxoPayPaymentActions';
import { OXXO_PAY_PAYMENT_MOUNT } from './oxxoPayConstants';
import checkoutGenericHOC from '../checkoutGeneric/checkoutGenericHOC';
import { PAYMENT_METHOD } from '../checkoutPayment/checkoutPaymentConstants';
import checkoutGenericContainer from '../checkoutGeneric/checkoutGenericContainer';

const mapStateToProps = ({ Global }) => ({
  selectedDeliveryMode: Global.cartData.deliveryMode.name,
  displayOxxoPay: Global.cartData.displayOxxoPay,
  isLoggedIn: Global.isLoggedIn,
  paymentType: PAYMENT_METHOD.OXXOPAY
});

const mapDispatchToProps = (dispatch) => ({
  getOxxoPayInfo() {
    dispatch(getOxxoPayInfo());
  },
  oxxoPayDetailsMountTrigger(deliveryMethod) {
    dispatch({ type: OXXO_PAY_PAYMENT_MOUNT, deliveryMethod });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(checkoutGenericContainer(checkoutGenericHOC(OxxoPayPayment)));
