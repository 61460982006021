import { connect } from 'react-redux';
import { FormActions } from 'react-forms';
import { CARD_DETAILS_MOUNT, PAYMENT_METHOD } from './checkoutPaymentConstants';
import { setPaymentInfo, replaceBillingAddress, resetBillingAddress, replaceAddressDetails, setCartToPay, messagePaymentOpenpay, setIframeLoadedCompleted } from './checkoutPaymentActions';
import { submitRegistration } from '../../../auth/authFormActions';
import { getCheckoutReviewData } from '../checkoutReview/checkoutReviewActions';
import { updateDeliveryMode } from '../deliveryModes/deliveryModesActions';
import { setServerMsg } from 'app/globalMessages/globalMessagesActions';
import { placeOrder, placeOrderWithCard, hasCheckoutLowerLimitError, addCreditCard, getCreditCards, saveCardData } from '../checkoutReview/orderSummary/placeOrder/placeOrderUtils';
import { setCoverageError } from 'app/checkout/checkoutActions';
import { placeOrderHideSpinner } from '../checkoutReview/checkoutReviewActions';
import { saveDeliveryAddress } from '../checkoutDelivery/checkoutDeliveryActions';

const mapStateToProps = ({ CheckoutRegister, Forms, Global, CheckoutReview, PaymentInfo, MemberIdLookup }) => {
  let selectedDeliveryAddress = (CheckoutRegister.selectedDeliveryAddress || {});
  const region = (selectedDeliveryAddress.region && selectedDeliveryAddress.region.isocode) ? selectedDeliveryAddress.region.isocode : selectedDeliveryAddress.region;
  const city = selectedDeliveryAddress.city || selectedDeliveryAddress.town;
  const guestUserData = (Global.guestUserData || {} );

  return ({
    MemberIdLookup,
    selectedDeliveryAddress,
    selectedDeliveryMode: Global.cartData.deliveryMode.name,
    deliveryModeId: Global.cartData.deliveryMode.id,
    registrationAddress: {
      username: CheckoutRegister.selectedDeliveryAddress.username,
      memberId: selectedDeliveryAddress.memberId,
      firstName: selectedDeliveryAddress.firstName,
      lastName: selectedDeliveryAddress.lastName,
      line1: selectedDeliveryAddress.line1,
      line2: selectedDeliveryAddress.line2,
      city: city,
      cityName: selectedDeliveryAddress.cityName,
      region: region,
      'region.isocode': region,
      postalCode: selectedDeliveryAddress.postalCode,
      country: selectedDeliveryAddress.country,
      cellPhoneNumber: selectedDeliveryAddress.cellPhoneNumber,
      unitNumber: selectedDeliveryAddress.unitNumber,
      colonia: selectedDeliveryAddress.colonia,
      coloniaName: selectedDeliveryAddress.coloniaName,
      landmark: selectedDeliveryAddress.landmark,
      deliverByReferralId: selectedDeliveryAddress.deliverByReferralId,
      acceptTermsAndPrivacy: selectedDeliveryAddress.acceptTermsAndPrivacy,
      password: (Forms['Payment-passwordAndConfirmation'] || { values: {} }).values.password
    },
    username: (Forms['Login-usernameAndPassword'] || { values: {} }).values.username || (Forms['registration-usernameAndPassword'] || { values: {} }).values.username,
    isGuest: Global.isGuestCheckout,
    guestUserData: guestUserData,
    isLoggedIn: Global.isLoggedIn,
    paymentType: PAYMENT_METHOD.CARD,
    guid: CheckoutReview.guid,
    products: CheckoutReview.entries,
    cardDetails: Forms['Payment-cardDetails'],
    paymentInfoDetails: (PaymentInfo.paymentInfoDetails) ? PaymentInfo.paymentInfoDetails : null
  });
};

const mapDispatchToProps = (dispatch) => ({
  triggerValidate(formName, trigger, shoudDispatchSubmit) {
    dispatch(FormActions.triggerValidate(formName, trigger, shoudDispatchSubmit));
  },
  resetFormData(formName) {
    dispatch(FormActions.reset(formName));
  },
  submitRegistration(...args) {
    dispatch(submitRegistration(...args));
  },
  setPaymentInfo(formData, deliveryModeId, updateStateCallback, placeOrderCallback) {
    dispatch(setPaymentInfo(formData, updateStateCallback))
        //.then(() => setTimeout(() => { dispatch(updateDeliveryMode(deliveryModeId)) }, 500))
        .then(() => placeOrderCallback());
  },
  updateDeliveryMode(deliveryModeId, placeOrderCallback) {
    dispatch(updateDeliveryMode(deliveryModeId))
      .then(() => placeOrderCallback());
  },
  getCheckoutReviewData() {
    dispatch(getCheckoutReviewData());
  },
  replaceBillingAddress(billingAddressGroups, selectedDeliveryAddress) {
    dispatch(replaceBillingAddress(billingAddressGroups, selectedDeliveryAddress));
  },
  resetBillingAddress(billingAddressGroups) {
    dispatch(resetBillingAddress(billingAddressGroups));
  },
  replaceAddressDetails(formTitles, data) {
    dispatch(replaceAddressDetails(formTitles, data));
  },
  cardDetailsMountTrigger(deliveryMethod) {
    dispatch({ type: CARD_DETAILS_MOUNT, deliveryMethod });
  },
  setServerMsg(...args){
    dispatch(setServerMsg(...args));
  },
  placeOrder(...args){
    dispatch(placeOrder(...args));
  },
  hasCheckoutLowerLimitError(...args){
    dispatch(hasCheckoutLowerLimitError(...args))
  },
  addCreditCard(...args) {
    dispatch(addCreditCard(...args))
  },
  getCreditCards(...args) {
    dispatch(getCreditCards(...args))
  },
  messagePaymentOpenpay(...args){
    dispatch(messagePaymentOpenpay(...args))
  },
  setCoverageError(valid, message){
    dispatch(setCoverageError(valid, message))
  },
  placeOrderHideSpinner(...args) {
    dispatch(placeOrderHideSpinner(...args))
  },
  setIframeLoadedCompleted(...args) {
    dispatch(setIframeLoadedCompleted(...args));
  },
  saveCardData(...args) {
    dispatch(saveCardData(...args));
  },
  setDeviceFingerPrint(...args) {
    dispatch(setDeviceFingerPrint(...args));
  },
  saveDeliveryAddress(...args) {
    dispatch(saveDeliveryAddress(...args));
  },
  onPlaceGuestOrder(formData, updateStateCallback, isFormCheck, paymentInfo, deliveryModeId, placeOrderCallback) {
    dispatch(saveDeliveryAddress(formData, updateStateCallback, isFormCheck))
      .then(() => dispatch(setPaymentInfo(paymentInfo, updateStateCallback)))
      .then(() => () => dispatch(updateDeliveryMode(deliveryModeId)))
      .then(() => placeOrderCallback());
  }
});

export default connect(mapStateToProps, mapDispatchToProps);
