import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import PasswordAndConfirmation from 'app/formComponents/passwordAndConfirmationFormGroup/passwordAndConfirmationFormGroup';
import { connect } from 'react-redux';
import GuestAddressForm from '../../../formComponents/guestAddressForm/guestAddressForm';
import { FormGroupAsync } from 'react-forms';
import checkoutGenericHOC from '../checkoutGeneric/checkoutGenericHOC';
import checkoutGenericContainer from '../checkoutGeneric/checkoutGenericContainer';
import Guest from '../guest/guestContainer';

const { registrationValidation } = window.inlineCheckout;

class AplazoPayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputsInUse: new Set()
    };

    this.isInputInUse = this.isInputInUse.bind(this);
    this.inputInUseHandler = this.inputInUseHandler.bind(this);
  }

  isInputInUse(inputName) {
    return this.state.inputsInUse.has(inputName)
  }

  inputInUseHandler(event, inputName) {
    event.preventDefault();

    const {
      inputsInUse
    } = this.state;

    if (event.target.value.length > 0) {
      inputsInUse.add(inputName);
    } else {
      inputsInUse.delete(inputName);
    }
    this.setState({
      inputsInUse
    });
  }

  componentDidMount() {
    const { aplazoDetailsMountTrigger, selectedDeliveryMode } = this.props;
    aplazoDetailsMountTrigger(selectedDeliveryMode);
  }

  render() {
    const { getAplazoInfo, onSubmitPayment, isLoggedIn, formGroups, isPriceOverTwoHundred, disableSubmit, isGuest, postcodeFormGroups, deliveryAddressFormGroups } = this.props;
    const handlersAndValidation = {
      handleValidForm: formGroups.resolve,
      handleInvalidForm: formGroups.reject,
      validation: registrationValidation
    };

    return (
      <div className="checkout-section__aplazo">
        <p className="checkout-section__aplazo-msg">
          <FormattedHTMLMessage id="checkout.billing.aplazo.message" description="Aplazo message" />
        </p>
        {
          !isLoggedIn && !isGuest &&
          <div className="row">
            <div className="columns large-6 medium-6 small-12">
              <span>Para poder rastrear o hacer una futura compra, por favor crea tu cuenta</span>
            </div>
            <div className="columns large-6 medium-6 small-12 register">
              <a className="btn-sendRegister" href="./login/auth#/register">Regístrarme</a>
            </div>
          </div>
        }
        { isGuest && isPriceOverTwoHundred && !disableSubmit &&
          <GuestAddressForm
            formGroups={deliveryAddressFormGroups}
            hasReference={false}
            isInputInUse={this.isInputInUse}
            inputInUseHandler={this.inputInUseHandler}
            // Postcode lookup related properties
            showPostcodeValidationForm={false}
            showPostcodeTryAnotherMsg={false}
            postcodeFormGroups={postcodeFormGroups}
          />
        }
        {
          (isLoggedIn || isGuest) &&
          <button id="btn-submitPayment" disabled={!isPriceOverTwoHundred && disableSubmit} className={isPriceOverTwoHundred && !disableSubmit && ((!this.props.MemberIdLookup.deliveryByReferralChecked || (!this.props.MemberIdLookup.isError && (this.props.MemberIdLookup.memberId !== null && this.props.MemberIdLookup.memberId !== undefined && this.props.MemberIdLookup.memberId !== '')))) ? "auth-form__register-btn-inside" : "auth-form__register-btn-inside-disabled"} onClick={(e) => onSubmitPayment(e, getAplazoInfo)}>
            <div className="sending-payment">
              <FormattedMessage id="checkout.billing.finalize.purchase.message" description="Change" />
            </div>
          </button>
        }
        {
          !isLoggedIn && !isGuest &&
          <Guest />
        }
      </div>
    );
  }
}

AplazoPayment.propTypes = {
  getAplazoInfo: PropTypes.func.isRequired,
  onSubmitPayment: PropTypes.func.isRequired,
  aplazoDetailsMountTrigger: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  formGroups: PropTypes.object.isRequired,
  isPriceOverTwoHundred: PropTypes.bool,
  disableSubmit: PropTypes.bool.isRequired,
};

AplazoPayment.contextTypes = {
  intl: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  isPriceOverTwoHundred: state.Global.isPriceOverTwoHundred,
});

export default connect(mapStateToProps)(checkoutGenericContainer(checkoutGenericHOC(AplazoPayment)));
