import { connect } from 'react-redux';
import PaypalPayment from './paypalPayment';
import { getPaypalInfo } from './paypalPaymentActions';
import { PAYPAL_PAYMENT_MOUNT } from './paypalConstants';
import { PAYMENT_METHOD } from '../checkoutPayment/checkoutPaymentConstants';

const mapStateToProps = (state) => ({
  MemberIdLookup: state.MemberIdLookup,
  selectedDeliveryMode: state.Global.cartData.deliveryMode.name,
  paymentType: PAYMENT_METHOD.PAYPAL
});

const mapDispatchToProps = (dispatch) => ({
  getPaypalInfo() {
    dispatch(getPaypalInfo());
  },
  payPalDetailsMountTrigger(deliveryMethod) {
    dispatch({ type: PAYPAL_PAYMENT_MOUNT, deliveryMethod });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PaypalPayment);