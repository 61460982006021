import { setSavedPaymentCards } from '../checkoutPayment/checkoutPaymentActions';
import { ajaxRequest } from 'app/utils/helpers';

const { paypalPaymentInfoUrl, billingPaypalErrorUrl } = window.inlineCheckout;

export const getPaypalInfo = () => dispatch => {
  ajaxRequest('GET', paypalPaymentInfoUrl)
    .then(
      (response) => {
        dispatch(setSavedPaymentCards(response.cart));
      },
      () => {
        window.location.href = billingPaypalErrorUrl;
      }
    );
};
