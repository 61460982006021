import { hashHistory } from 'react-router';
import { omit, isEmpty } from 'lodash';
import { REVIEW_URL, SCROLL_TO_ADDRESS_FORM_DURATION, BILLING_URL, DELIVERY_URL } from '../../checkoutConstants';
import { setServerMsg } from 'app/globalMessages/globalMessagesActions';
import * as MSG from 'app/globalMessages/globalMessagesConstants';
import genericErrorHandler from 'app/utils/serverErrors';
import { FormActions } from 'react-forms';
import * as CONST from './checkoutPaymentConstants';
import { ajaxRequest, scrollToPosition, getElementPosition } from 'app/utils/helpers';
import { mapToBilling } from 'app/formComponents/addressForm/addressFormUtils';
import { sessionExpiredHandler } from '../../checkoutActions';

const { setPaymentInfoUrl, billingCardErrorUrl } = window.inlineCheckout;

const defaultPaymentInfo = {
  paymentInfo: {
    hasPaymentToken: false
  }
};

export const setSavedPaymentCards = (cartData = defaultPaymentInfo) => ({
  type: CONST.SET_PAYMENT_INFO,
  cartData
});

export const setIframeLoadedCompleted = (iframe) => ({
  type: CONST.SET_IFRAME_COMPLETED,
  iframe
});

export const setPaymentInfo = (formData, updateStateCallback) =>
  (dispatch) => {
    if (updateStateCallback) {
      updateStateCallback({ disableSubmit: true });
    }

    return ajaxRequest('POST', setPaymentInfoUrl, formData, sessionExpiredHandler()).then(
      (res) => {
        dispatch(setSavedPaymentCards({ paymentInfo: res }));
        if (res.status === 'success' || res.id) {
          return;
        } else {
          if (updateStateCallback) {
            updateStateCallback({ disableSubmit: false });
          }
          //window.scrollTo(0, 0);
          dispatch(setServerMsg(res.message, MSG.TYPE_ERROR, true, false));
        }
        return;
      },
      ({ status }) => genericErrorHandler(dispatch, status)
    );
  };

export const replaceBillingAddress = (billingAddressGroups, selectedDeliveryAddress) =>
  dispatch => {
    const copyDeliveryAddressWithoutID = omit(selectedDeliveryAddress, ['id']);
    scrollToPosition(getElementPosition(document.querySelector('.address-form')), SCROLL_TO_ADDRESS_FORM_DURATION);
    copyDeliveryAddressWithoutID['region.isocode'] = selectedDeliveryAddress.region.isocode;

    billingAddressGroups.forEach(group =>
      dispatch(
        FormActions.setDataReplace(group, copyDeliveryAddressWithoutID)
      )
    );
  };

export const handlePaymentOptionAndRedirects = (cartData, isFormCheck) => (dispatch, getState) => {
  const { paymentInfo } = getState().CheckoutPayment;
  const hasPaymentInfo = (paymentInfo && (paymentInfo.hasPaymentToken || getState().Global.isZeroValuePayment));
  const cardDetails = getState().Forms['Payment-cardDetails'];
  const hasCardDetails = (cardDetails && !isEmpty(cardDetails.values));
  if (!hasPaymentInfo) {
    if (hasCardDetails) {
      const cartBillingAddressData = cartData.paymentInfo.billingAddress;

      const adaptedFormData = mapToBilling(Object.assign({}, {
        titleCode: cartBillingAddressData.titleCode,
        firstName: cartBillingAddressData.firstName,
        lastName: cartBillingAddressData.lastName,
        phone: cartBillingAddressData.phone,
        line1: cartBillingAddressData.line1,
        line2: cartBillingAddressData.line2,
        postalCode: cartBillingAddressData.postalCode,
        country: cartBillingAddressData.country.isocode,
        city: cartBillingAddressData.town,
        colonia: cartBillingAddressData.colonia,
        cellPhoneNumber: cartBillingAddressData.cellPhoneNumber,
        'region.isocode': cartBillingAddressData.region ? cartBillingAddressData.region.isocode : null,
        saved: cartData.paymentInfo.saved
      }));
      dispatch(setPaymentInfo(adaptedFormData));
    } else {
      if (getState().Global.isGuestCheckout && !getState().Global.isLoggedIn && !isFormCheck) {
        hashHistory.push(DELIVERY_URL);
      } else {
        hashHistory.push(BILLING_URL);
      }
    }

    return;
  }

  hashHistory.push(BILLING_URL);
};

export const resetBillingAddress = billingAddressGroups =>
  dispatch => billingAddressGroups.forEach(group =>
    dispatch(FormActions.reset(group))
  );

export const replaceAddressDetails = (formTitles, data) =>
  dispatch => {
    formTitles.forEach(group => {
      dispatch(FormActions.setDataReplace(group, data));
    });
  };

export const messagePaymentOpenpay = (errorOpenpay) => (dispatch) => {
  window.scrollTo(0, 0);
  dispatch(setServerMsg(errorOpenpay, MSG.TYPE_ERROR, true));
  setTimeout(function(){
    window.location.href = billingCardErrorUrl;
  }, CONST.REDIRECT_TIME);
};