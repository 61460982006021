import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';
import { FormGroupAsync } from 'react-forms';
import { mapToBilling, deliveryAddressMapper, removeSensitiveInfo } from 'app/formComponents/addressForm/addressFormUtils';
import {
  formDataForComparison,
  getValidAddressDetails,
  scrollToEl,
  scrollToError,
  validateNorthAmerica,
  validatePostcode
} from 'app/formComponents/formComponentsUtils';
import { topWrapperOffset, parseUndefinedAndNull } from 'app/utils/helpers';
import mediaQueries from 'app/utils/mediaQueries';
import { ajaxFetchRequest } from 'app/utils/helpers';
import {
  isCartProductsEqual,
  onPaymentFail,
  onPlaceOrderCartUpdated,
  onPlaceOrderDataReset
} from '../checkoutReview/orderSummary/placeOrder/placeOrderUtils';
import {
  CARD_TYPES,
  PLACE_ORDER_STATUSES,
  PLACE_ORDER_TIMEOUT
} from '../checkoutReview/orderSummary/placeOrder/placeOrderConstants';
import { fetchCartData, placeOrderShowSpinner /*, checkStock*/ } from '../../checkoutActions';
import store from '../../checkoutStore';
import { PAYMENT_METHOD } from './checkoutPaymentConstants';
import { getPaypalInfo } from '../paypalPayment/paypalPaymentActions';
import * as MSG from 'app/globalMessages/globalMessagesConstants';
import { FormattedMessage } from 'react-intl';

const { qasLookup, locale, qasValidate } = window.inlineGlobalConfig;
const { createChargeUrl, coverageUrl, setDeviceFingerPrintUrl, setPaymentCardUrl, payerAuthenticateUrl, validateAuthenticateUrl, executePaymentUrl, generateJwtUrl, decisionUrl, transactionProcessorUrl, checkStockUrl, inactiveProductsInCartUrl, updateMemberLookupUrl } = window.inlineCheckout;

const {
  registrationSubmitUrl
} = window.inlineGlobalConfig;

const CheckoutPaymentHOC = (WrappedComponent) => {
  class CheckoutPayment extends Component {
    constructor(props, context) {
      super(props);
      this.context = context;
      this.state = {
        isModalOpen: false,
        topOffset: null,
        selectedRegion: '',

        isNewBillingAddress: true,
        gotSavedCard: true,
        isCapturingNewCard: false,

        initialAddressData: null,
        validatedForm: null,
        incorrectFormData: false,
        entries: [],

        showPostcodeValidationForm: false,
        showPostcodeTryAnotherMsg: false,
        showPostcodeSelectAddress: false,
        postcodeEntries: [],

        disableSubmit: false,

        renderModal: false,

        iframeFinishLoaded: null
      };

      this.formName = 'Payment';
      this.formGroups = new FormGroupAsync({
        formName: this.formName,
        groups: this.props.isLoggedIn ? ['cardDetails'] : ['cardDetails', 'passwordAndConfirmation', 'confirmPassword']
      });

      this.formGroups2 = new FormGroupAsync({
        formName: this.formName,
        groups: ['passwordAndConfirmation']
      });

      this.billingAddressFormGroups = new FormGroupAsync({
        formName: this.formName,
        groups: ['address',
                 'countrySelector',
                 'postCodeSuggestionRegion',
                 'postCodeSuggestionCity',
                 'postCodeSuggestionColonia',
                 'postCodeSuggestionPostalCode']
      });

      this.postcodeFormGroups = new FormGroupAsync({
        formName: this.formName,
        groups: ['postcodeValidation']
      });

      this.deliveryAddressFormGroups = new FormGroupAsync({
        formName: this.formName,
        groups: [
          'phoneNumber',
          'address',
          'countrySelector',
          'postCodeSuggestionRegion',
          'postCodeSuggestionCity',
          'postCodeSuggestionColonia',
          'postCodeSuggestionPostalCode',
          'memberId'
        ]
      });

      this.deliveryPostcodeFormGroups = new FormGroupAsync({
        formName: this.formName,
        groups: ['postcodeValidation']
      });

      this.onSubmit = this.onSubmit.bind(this);
      this.onSubmitPayment = this.onSubmitPayment.bind(this);
      this.handleValidPaymentForm = this.handleValidPaymentForm.bind(this);
      this.handleValidForm = this.handleValidForm.bind(this);
      this.handleValidForm2 = this.handleValidForm2.bind(this);
      this.addNewCard = this.addNewCard.bind(this);
      this.toggleUseBillingAddress = this.toggleUseBillingAddress.bind(this);

      this.toggleModal = this.toggleModal.bind(this);
      this.updateStateCallback = this.updateStateCallback.bind(this);
      this.handleFindAddressClick = this.handleFindAddressClick.bind(this);

      this.onPostcodeSubmit = this.onPostcodeSubmit.bind(this);
      this.handlePostcodeValidForm = this.handlePostcodeValidForm.bind(this);
      this.handlePostcodeContinueClick = this.handlePostcodeContinueClick.bind(this);
      this.togglePostcodeValidation = this.togglePostcodeValidation.bind(this);
      this.togglePostcodeSelectAddress = this.togglePostcodeSelectAddress.bind(this);
      this.onPlaceOrder = this.onPlaceOrder.bind(this);
      this.authenticatePaymentProcess = this.authenticatePaymentProcess.bind(this);
      this.paymentProcess = this.paymentProcess.bind(this);
      this.continueProcess = this.continueProcess.bind(this);
      this.fetchDeviceFingerPrint =  this.fetchDeviceFingerPrint.bind(this);
      this.onPlaceOrder2 = this.onPlaceOrder2.bind(this);
      this.toggleModal = this.toggleModal.bind(this);
      this.iframeRefCallback = this.iframeRefCallback.bind(this);
      this.iframeRefCallbackSteps = this.iframeRefCallbackSteps.bind(this);
      this.checkStock = this.checkStock.bind(this);
      this.checkCoverage = this.checkCoverage.bind(this);
      this.generateJwt = this.generateJwt.bind(this);
      this.validatePayer = this.validatePayer.bind(this);
      this.authenticationPayer = this.authenticationPayer.bind(this);
      this.transactionProcessor = this.transactionProcessor.bind(this);
      this.decision = this.decision.bind(this);
      this.displayGeneralError = this.displayGeneralError.bind(this);
      this.displayInvalidCardError =  this.displayInvalidCardError.bind(this);
      this.executeSpinner = this.executeSpinner.bind(this);
      this.inactiveProductsInCart = this.inactiveProductsInCart.bind(this);
      this.displayInactiveProductError = this.displayInactiveProductError.bind(this);

      this.fillPlaceOrderForm = this.fillPlaceOrderForm.bind(this);
      this.updateMemberLookup = this.updateMemberLookup.bind(this);
    }

    componentDidMount() {
      const { selectedDeliveryAddress, cardDetailsMountTrigger, selectedDeliveryMode } = this.props;
      const { isUseDeliveryDetails } = this.state;

      cardDetailsMountTrigger(selectedDeliveryMode);

      const addressLine2Value = selectedDeliveryAddress.line2 ? selectedDeliveryAddress.line2 : '';
      const region = selectedDeliveryAddress.region ? selectedDeliveryAddress.region.isocode : null;

      const addressCountry = (selectedDeliveryAddress.country && selectedDeliveryAddress.country.isocode) ?
        selectedDeliveryAddress.country.isocode :
        selectedDeliveryAddress.country;

      const addressCity = (selectedDeliveryAddress.town && !selectedDeliveryAddress.city) ?
        selectedDeliveryAddress.town :
        selectedDeliveryAddress.city;

      const showPostcodeValidationForm = qasLookup.split(', ').includes(locale.country) && !isUseDeliveryDetails;
      const showPostcodeTryAnotherMsg = qasLookup.split(', ').includes(locale.country);

      this.props.getCheckoutReviewData();

      this.setState({
        isNewBillingAddress: !this.props.selectedDeliveryAddress,
        selectedRegion: region,
        initialAddressId: selectedDeliveryAddress.id,
        initialAddressData: {
          country: addressCountry,
          line1: selectedDeliveryAddress.line1,
          line2: addressLine2Value,
          postalCode: selectedDeliveryAddress.postalCode,
          region: isEmpty(region) ? null : region,
          city: addressCity
        },
        showPostcodeValidationForm,
        showPostcodeTryAnotherMsg
      });
    }

    componentWillReceiveProps(nextProps) {
      this.setState({
        gotSavedCard: nextProps.paymentInfoDetails.length > 0
      });
    }

    componentWillUpdate(nextProps) {
      if (this.isCardPayment && nextProps.generateSopFormState) {
        this.placeOrderIFrame.contentWindow.location.reload(true);
        this.props.generateSopForm(false);
      }
    }

    executeSpinner(){
      store.dispatch(placeOrderShowSpinner());
      document.getElementsByClassName('sending-payment')[0].innerHTML = '<div class="spinner active"><div class="spinner__content"><div class="spinner__icon"><span class="icon-loop"></span></div><div class="spinner__text"><span>Cargando...</span></div></div></div>';
      document.getElementById('btn-submitPayment').setAttribute('disabled','true');
      document.getElementById('btn-submitPayment').className = "auth-form__register-btn-inside-disabled";
    }

    onSubmit(e) {
      e.preventDefault();
      const { isGuest, triggerValidate } = this.props;

      if(this.props.MemberIdLookup.deliveryByReferralChecked && (this.props.MemberIdLookup.isError || (this.props.MemberIdLookup.memberId === null || this.props.MemberIdLookup.memberId === undefined || this.props.MemberIdLookup.memberId === '')))
      {
        this.setState({
          disableSubmit: false
        });

        scrollToError('.member-form-group');
        return;

      } else {
        this.executeSpinner();

            if (isGuest) {
              this.handleValidPaymentForm({});
            } else if (!isGuest) {
              this.formGroups
                .each(group => triggerValidate(group))
                .then(this.handleValidPaymentForm, true)
                .fail(() => scrollToError('.payment-form'));
            } else {
              this.handleValidPaymentForm({});
            }
      }
    }

    onSubmitPayment(e, onPlaceOrderCallback) {
      e.preventDefault();
      const { triggerValidate } = this.props;
      if (!this.props.isLoggedIn) {
        this.formGroups2
          .each(group => triggerValidate(group))
          .then(formData => this.handleValidForm2(formData, getPaypalInfo), true)
          .fail(() => scrollToError('.payment-form'));
      } else {
        this.handleValidForm2(null, onPlaceOrderCallback);
      }
    }

    onPostcodeSubmit(e) {
      e.preventDefault();
      const { triggerValidate } = this.props;
      this.postcodeFormGroups
        .each(group => triggerValidate(group))
        .then(this.handlePostcodeValidForm, true)
        .fail(() => scrollToError('.address-form'));
    }

    updateStateCallback(state) {
      this.setState(state);
    }

    handleFindAddressClick(stringAddress) {
      const { replaceAddressDetails } = this.props;

      if (stringAddress !== null && stringAddress && stringAddress.addressString) {
        getValidAddressDetails(
          stringAddress,
          replaceAddressDetails,
          this.formGroups,
          this.updateStateCallback
        );
      } else {
        const addressGroupsToReplace = [
          this.billingAddressFormGroups.getName('countrySelector'),
          this.billingAddressFormGroups.getName('addressFormGroup'),
          this.billingAddressFormGroups.getName('addressRegion')
        ];
        replaceAddressDetails(addressGroupsToReplace, stringAddress);
      }

      scrollToEl('.billing-address-details');
    }

    handleValidPaymentForm(paymentFormData) {
      const { triggerValidate, isGuest } = this.props;

      if (isGuest){
        this.formGroups
          .each(group => triggerValidate(group))
          .then(this.handleValidPaymentForm, true)
          .fail(() => scrollToError('.payment-form'));

        this.deliveryAddressFormGroups
          .each(group => triggerValidate(group))
          .then((deliveryFormData) => this.handleDeliveryValidForm(paymentFormData, deliveryFormData), true)
          .fail((error) => {
            scrollToError('.billing-address-details');
          });
      } else if (this.state.isNewBillingAddress) {
        this.billingAddressFormGroups
          .each(group => triggerValidate(group))
          .then((billingFormData) => this.handleValidForm(paymentFormData, billingFormData), true)
          .fail((error) => {
            scrollToError('.billing-address-details');
          });
      } else {
        this.handleValidForm(paymentFormData);
      }
    }

    populateDataPaymentInfo(formData, guestUserData, deliveryModeId) {
      let firstName = (guestUserData.firstName) ? guestUserData.firstName : '';
      let lastName = (guestUserData.lastName) ? guestUserData.lastName : '' ;

      const formDataForSetPaymentInfo = mapToBilling({
        ...formData,
        firstName,
        lastName,
        phone: formData.phone,
        cellPhoneNumber: formData.cellPhoneNumber,
        'region.isocode': formData.region,
        country: formData.country,
        saved: false
      });

      return formDataForSetPaymentInfo;
    }


    populateDataAddressInfo(formData, guestUserData, deliveryModeId) {
      let firstName = (guestUserData.firstName) ? guestUserData.firstName : '';
      let lastName = (guestUserData.lastName) ? guestUserData.lastName : '' ;

      const formDataDeliveryAddress = {
        deliveryModeId: deliveryModeId,
        firstName,
        lastName,
        phone            : formData.phone,
        cellPhoneNumber  : formData.cellPhoneNumber,
        line1            : formData.line1,
        line2            : formData.line2,
        city             : formData.cityName,
        postalCode       : formData.postalCode,
        country : formData.country,
        'region.isocode' : formData.region,
        colonia        : formData.coloniaName,
        unitNumber: formData.unitNumber
      };

      return formDataDeliveryAddress;
    }

    populateRegisterGuestData(deliveryFormData) {
       const { guestUserData, guid } = this.props;

       let deliveryByReferralChecked = (this.props.MemberIdLookup.deliveryByReferralChecked
                                  && this.props.MemberIdLookup.memberId !== null
                                  && this.props.MemberIdLookup.memberId !== '') ?
                                  true : false;

       let memberId = this.props.MemberIdLookup.memberId;

       let firstName = (guestUserData.firstName) ? guestUserData.firstName : '';
       let lastName = (guestUserData.lastName) ? guestUserData.lastName : '' ;
       let email = guestUserData.email;

       let password = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
                          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
                        ).replaceAll('-','');

       let cellPhoneNumber = deliveryFormData.cellPhoneNumber;
       let city = deliveryFormData.city;
       let cityName = deliveryFormData.cityName;
       let colonia = deliveryFormData.colonia;
       let coloniaName = deliveryFormData.coloniaName;
       let country = deliveryFormData.country;
       let line1 = deliveryFormData.line1;
       let line2 = deliveryFormData.line2;
       let phone = deliveryFormData.phone;
       let postalCode = deliveryFormData.postalCode;
       let region = deliveryFormData.region;
       let saved = deliveryFormData.saved;
       let unitNumber = deliveryFormData.unitNumber;

       return {
         username: email,
         memberId,
         firstName,
         lastName,
         line1,
         line2,
         city,
         cityName,
         region,
         'region.isocode': region,
         postalCode,
         country,
         cellPhoneNumber,
         unitNumber,
         colonia,
         coloniaName,
         landmark: "",
         deliveryByReferralChecked,
         acceptTermsAndPrivacy: true,
         password: password,
         isReferalLinkGroup: false,
         guid,
         customerStatus: "GUEST"
       }
    }

    handleDeliveryValidForm(paymentFormData, deliveryFormData) {
      const { onPlaceGuestOrder, setPaymentInfo, guestUserData, deliveryModeId, guid, submitRegistration, setCoverageError } = this.props;

      const formDataForSetPaymentInfo = this.populateDataPaymentInfo(deliveryFormData, guestUserData, deliveryModeId);
      const formDataDeliveryAddress = this.populateDataAddressInfo(deliveryFormData, guestUserData, deliveryModeId);

      const emailErrorDetails = {
        formGroupName: null
      };

     /*
     saveDeliveryAddress(formDataDeliveryAddress, () => setPaymentInfo(formDataForSetPaymentInfo, deliveryModeId, this.updateStateCallback, () => {
       setTimeout(function(){ this.onPlaceOrder2(); }.bind(this), 1000);
     }));
     */

    onPlaceGuestOrder(formDataDeliveryAddress, () => {}, true, formDataForSetPaymentInfo, deliveryModeId, () => { this.onPlaceOrder2(); });


     return;

     /*
      ajaxFetchRequest('POST', guid ? `${registrationSubmitUrl}?guid=${guid}` : registrationSubmitUrl, this.populateRegisterGuestData(deliveryFormData))
      .then(
        (res) => {
          if(res.success)
          {
            saveDeliveryAddress(formDataDeliveryAddress, () => setPaymentInfo(formDataForSetPaymentInfo, deliveryModeId, this.updateStateCallback, () => {
                setTimeout(function(){ this.onPlaceOrder2(); }.bind(this), 1000);
            }));
          } else {
            store.dispatch(setCoverageError(false, res.error));
          }
        });
        return;
      */
    }

    populateDataPaymentInfo(formData, guestUserData, deliveryModeId) {

      const formDataForSetPaymentInfo = mapToBilling(Object.assign({}, {
              firstName: guestUserData.firstName,
              lastName: guestUserData.lastName,
              line1: formData.line1,
              line2: formData.line2,
              postalCode: formData.postalCode,
              country: formData.country,
              city: formData.cityName,
              colonia: formData.coloniaName,
              phone: formData.phone,
              cellPhoneNumber: formData.cellPhoneNumber,
              'region.isocode': formData.region,
              saved: false
            }));

      return formDataForSetPaymentInfo;
    }

    populateDataAddressInfo(formData, guestUserData, deliveryModeId) {

      const formDataDeliveryAddress = {
        deliveryModeId: deliveryModeId,
        phone            : formData.phone,
        cellPhoneNumber  : formData.cellPhoneNumber,
        line1            : formData.line1,
        line2            : formData.line2,
        city             : formData.cityName,
        postalCode       : formData.postalCode,
        country : formData.country,
        'region.isocode' : formData.region,
        colonia        : formData.coloniaName,
        unitNumber: (formData.unitNumber) ? formData.unitNumber : ''
      };

      return formDataDeliveryAddress;
    }

    handleValidForm(paymentFormData, billingFormData) {
      const { isLoggedIn, setPaymentInfo, saveDeliveryAddress, submitRegistration, updateDeliveryMode, deliveryModeId, isGuest } = this.props;
      const { gotSavedCard, isCapturingNewCard, isNewBillingAddress } = this.state;

      this.executeSpinner();

      this.setState({
        incorrectFormData: false
      });

      const qasValidationRequired = qasValidate.split(', ').includes(locale.country);

      const formData = Object.assign(this.props.registrationAddress, paymentFormData);
      const formDataToCompare = formDataForComparison(paymentFormData);

      const emailErrorDetails = {
        formGroupName: this.formGroups.groups[0]
      };

      const formDataForRegistration = removeSensitiveInfo(formData);
      const formDataForSetPaymentInfo = (isNewBillingAddress)
                     ? mapToBilling({
                         ...billingFormData,
                         firstName: formData.firstName,
                         lastName: formData.lastName,
                         'region.isocode': formData.region,
                         phone: formData.phone,
                         cellPhoneNumber: formData.cellPhoneNumber,
                         country: formData.country,
                         saved: formData.saved
                       })
                     : mapToBilling(formDataForRegistration);

      if (qasValidationRequired && !isEqual(formDataToCompare, this.state.initialAddressData)) {
        validateNorthAmerica(
          this.state.validatedForm,
          formData,
          this.toggleModal,
          this.updateStateCallback,
          setPaymentInfo,
          null,
          'prepareForBilling'
        );
      } else {
        const formDataForRegistration = removeSensitiveInfo(formData);
        const formDataForSetPaymentInfo = (isNewBillingAddress)
          ? mapToBilling({
              ...billingFormData,
              firstName: formData.firstName,
              lastName: formData.lastName,
              'region.isocode': formData.region,
              phone: formData.phone,
              cellPhoneNumber: formData.cellPhoneNumber,
              country: formData.country,
              saved: formData.saved
            })
          : mapToBilling(formDataForRegistration);

        if (!isLoggedIn) {
          submitRegistration(formDataForRegistration, null, emailErrorDetails, null,
            () => setPaymentInfo(formDataForSetPaymentInfo, deliveryModeId, this.updateStateCallback, this.onPlaceOrder2));
        } else {
          if (!gotSavedCard || isCapturingNewCard) {
            setPaymentInfo(formDataForSetPaymentInfo, deliveryModeId, this.updateStateCallback, this.onPlaceOrder2);
          } else {
            updateDeliveryMode(deliveryModeId, this.onPlaceOrder2);
          }
        }
      }
    };

    handleValidForm2(formData, placeOrderCallback) {
      const { submitRegistration, placeOrderHideSpinner } = this.props;

      this.setState({
        incorrectFormData: false
      });

      formData = Object.assign(this.props.registrationAddress, formData);

      const emailErrorDetails = {
        formGroupName: this.formGroups.groups[0]
      };

      if (!this.props.isLoggedIn) {
        submitRegistration(formData, null, emailErrorDetails, null, placeOrderCallback);
      } else {
        placeOrderCallback();
        this.continueProcess();
      }
    };

    onPlaceOrder2() {
      this.setState({ renderModal: true });
      this.prepareCardPayment();
    }

    fetchDeviceFingerPrint(){
      const { setCoverageError, placeOrderHideSpinner } = this.props;
      return new Promise((resolve, reject) => {
        fetch(setDeviceFingerPrintUrl)
          .then(response => response.json())
          .then(res => {
            if (res.deviceFingerPrint) {
              resolve(true);
            } else {
              let errorMessage = this.context.intl.formatMessage({ id: 'checkout.order.placement.general.error', description: 'Error device finger print' }) ;
              store.dispatch(setCoverageError(false, errorMessage));
            }
          }).catch((err) => {
            let errorMessage = this.context.intl.formatMessage({ id: 'checkout.order.placement.general.error', description: 'Error device finger print' }) ;
            store.dispatch(setCoverageError(false, errorMessage));
          });
      });
    }

    log(msg) {
        var args = [].slice.call(arguments, 0);
        args[0] = '[cruise-tester] ' + args[0];
        return console.log.apply(this, args);
    }

    getHttpBrowserTimeDifference() {
      var fechaUTC = new Date();
      var anoUTC = fechaUTC.getUTCFullYear();
      var mesUTC = fechaUTC.getUTCMonth();
      var diaUTC = fechaUTC.getUTCDate();
      var horasUTC = fechaUTC.getUTCHours();
      var minutosUTC = fechaUTC.getUTCMinutes();
      var fechaUTCCompleta = new Date(anoUTC, mesUTC, diaUTC, horasUTC, minutosUTC);
      var fechaLocal = new Date();
      var diferenciaEnMinutos = (fechaUTCCompleta - fechaLocal) / (1000 * 60);
      return parseInt(Math.round(diferenciaEnMinutos));
    }

    getHttpBrowserScreenHeight() {
      return screen.height;
    }
    getHttpBrowserScreenWidth() {
      return screen.width;
    }

    getUserAgentBrowserValue() {
      return navigator.userAgent;
    }

    getHttpBrowserColorDepth() {
      return window.screen.colorDepth;
    }

    isHttpBrowserJavaScriptEnabled(){
      return (typeof window !== 'undefined' && typeof window.document !== 'undefined');
    }

    isHttpBrowserJavaEnabled(){
      return navigator.javaEnabled();
    }

    getHttpBrowserLanguage() {
      return navigator.language;
    }

    getHttpAcceptContent() {
      return "";
    }

    getHttpAcceptBrowserValue() {
      return "";
    }

    authenticationPayer(cardNumber, expirationMonth, expirationYear, transaction_uuid){
      return new Promise((resolve, reject) => {

          var formDataCard = new FormData();
          formDataCard.append('number', cardNumber);
          formDataCard.append('expirationMonth', expirationMonth);
          formDataCard.append('expirationYear', expirationYear);
          formDataCard.append('transactionUUID', transaction_uuid);

          formDataCard.append('httpAcceptBrowserValue', this.getHttpAcceptBrowserValue());
          formDataCard.append('httpAcceptContent', this.getHttpAcceptContent());
          formDataCard.append('httpBrowserLanguage', this.getHttpBrowserLanguage());
          formDataCard.append('httpBrowserJavaEnabled', this.isHttpBrowserJavaEnabled());
          formDataCard.append('httpBrowserJavaScriptEnabled', this.isHttpBrowserJavaScriptEnabled());
          formDataCard.append('httpBrowserColorDepth', this.getHttpBrowserColorDepth());
          formDataCard.append('httpBrowserTimeDifference', this.getHttpBrowserTimeDifference());
          formDataCard.append('userAgentBrowserValue', this.getUserAgentBrowserValue());
          formDataCard.append('httpBrowserScreenHeight', this.getHttpBrowserScreenHeight());
          formDataCard.append('httpBrowserScreenWidth', this.getHttpBrowserScreenWidth());

          fetch(payerAuthenticateUrl, {
            method: 'POST',
            body: formDataCard,
          }).then(res => res.json())
          .catch(error => reject(error))
          .then(response => {
            resolve(response);
          });
      });
    }

    validatePayer(cardNumber, expirationMonth, expirationYear, transactionId, transaction_uuid){
      return new Promise((resolve, reject) => {

        var formDataCard = new FormData();
        formDataCard.append('number', cardNumber);
        formDataCard.append('expirationMonth', expirationMonth);
        formDataCard.append('expirationYear', expirationYear);
        formDataCard.append('authenticationTransactionId', transactionId);
        formDataCard.append('transactionUUID', transaction_uuid);

        fetch(validateAuthenticateUrl, {
          method: 'POST',
          body: formDataCard,
        }).then(res => res.json())
        .catch(error => reject(error))
        .then(response => {
          resolve(response);
        });
      });
    }

    transactionProcessor(merchantReferenceCode, actionCode, requestID)
    {
        return new Promise((resolve, reject) => {
            var formDataCard = new FormData();
            formDataCard.append('merchantReferenceCode', merchantReferenceCode);
            formDataCard.append('actionCode', actionCode);
            formDataCard.append('requestID', requestID);

            fetch(transactionProcessorUrl, {
                method: 'POST',
                body: formDataCard,
            }).then(res => res.json())
            .catch(error => reject(error))
            .then(response => {
                resolve(response);
            });
        });
    }

    decision(transaction_uuid, cardNumber, expirationMonth, expirationYear, securityCode)
    {
        return new Promise((resolve, reject) => {
           var formDataCard = new FormData();
           formDataCard.append('transaction_uuid', transaction_uuid);
           formDataCard.append('number', cardNumber);
           formDataCard.append('expirationMonth', expirationMonth);
           formDataCard.append('expirationYear', expirationYear);
           formDataCard.append('securityCode', securityCode);

           fetch(decisionUrl, {
             method: 'POST',
             body: formDataCard,
           }).then(res => res.json())
           .catch(error => reject(error))
           .then(response => {
               resolve(response);
           });
        });
    }

    generateJwt(){
          return new Promise((resolve, reject) => {
            fetch(generateJwtUrl + "/?transactionId=" + transaction_uuid, {
              method: 'GET',
              body: null,
            }).then(res => res.json())
            .catch(error => reject(error))
            .then(response => {
              resolve(response);
            });
          });
        }

    displayInactiveProductError(products)
    {
      const { setCoverageError } = this.props;
      let message = this.context.intl.formatMessage({ id: 'checkout.order.placement.inactive.product.error', description: 'general error' });
      message = message.replace('{0}', products);
      store.dispatch(setCoverageError(false, message));
    }

    displayGeneralError()
    {
      const { setCoverageError } = this.props;
      store.dispatch(setCoverageError(false, this.context.intl.formatMessage({ id: 'checkout.order.placement.general.error', description: 'general error' })));
    }

    displayInvalidCardError()
    {
      const { setCoverageError } = this.props;
      store.dispatch(setCoverageError(false, this.context.intl.formatMessage({ id: 'checkout.order.placement.invalid.card.error', description: 'invalid card error' })));
    }

    continueProcess()
    {
      this.inactiveProductsInCart()
        .then(response3 => {
          this.checkStock()
          .then(response2 => {
            this.paymentProcess();
          }).catch(error => {
            this.displayGeneralError();
            return;
          });
        }).catch(error => {
          this.displayGeneralError();
          return;
        });
    }

    paymentProcess()
    {
      const { cardNumber, cardCVN, cardExpiryDate, cardType } = this.prepareCardDetails();
      let expirationMonth = cardExpiryDate.split('-')[0] || '';
      let expirationYear = cardExpiryDate.split('-')[1] || '';

      let onPlaceOrder = this.onPlaceOrder.bind(this);
      let authenticatePaymentProcess = this.authenticatePaymentProcess.bind(this);
      let displayGeneralError = this.displayGeneralError.bind(this);
      let displayInvalidCardError = this.displayInvalidCardError.bind(this);

      this.fetchDeviceFingerPrint()
          .then(response => {
            this.decision(transaction_uuid, cardNumber, expirationMonth, expirationYear, '')
            .then(response => {
              let status = response.response.status;
              let decisionId = response.response.id;

              if(status === "REJECTED")
              {
                displayInvalidCardError();
                return;
              } else if(status === "ACCEPTED")
              {
                let form = (document.getElementById('sopFormIframe').contentWindow.document).querySelector('#sopRequestForm');
                form.querySelector('#payerAuthentication').value = "false";
                onPlaceOrder();
              } else
              {
                authenticatePaymentProcess(decisionId);
              }
            });
          })
      .catch(error => {
        displayGeneralError();
        return;
      });
    }

    authenticatePaymentProcess(decisionId) {
      const { iframeAuthenticationSetups, cardDetails, setCoverageError } = this.props;
      const { cardNumber, cardCVN, cardExpiryDate, cardType } = this.prepareCardDetails();

      let generateJwt = this.generateJwt.bind(this);
      let validatePayer = this.validatePayer.bind(this);
      let authenticationPayer = this.authenticationPayer.bind(this);
      let transactionProcessor = this.transactionProcessor.bind(this);
      let decision = this.decision.bind(this);
      let onPlaceOrder = this.onPlaceOrder.bind(this);
      let displayGeneralError = this.displayGeneralError.bind(this);
      let displayInvalidCardError = this.displayInvalidCardError.bind(this);

      let expirationMonth = cardExpiryDate.split('-')[0] || '';
      let expirationYear = cardExpiryDate.split('-')[1] || '';

      let transactionId = null;

      this.generateJwt()
      .then(responseJwt => {
        var jwt = responseJwt.response.jwt;

        var orderObject = {
          Consumer: {
            Account: {
              AccountNumber: cardNumber
            }
          }
        };

        Cardinal.configure({
          logging: {
            level: 'verbose'
          }
        });

        Cardinal.setup('init', {
          jwt: jwt,
          order: orderObject
        });

        Cardinal.on('payments.setupComplete', function (e)
        {
          authenticationPayer(cardNumber, expirationMonth , expirationYear, transaction_uuid)
            .then(response => {
              transactionId = response.authenticationPayerProcess.authenticationTransactionId;
              let status = response.status;

              if (status == "PENDING_AUTHENTICATION") {
                Cardinal.continue('cca',
                {
                  "AcsUrl" : response.authenticationPayerProcess.acsUrl,
                  "Payload" : response.authenticationPayerProcess.pareq,
                },
                {
                  "OrderDetails": {
                    "TransactionId" : transactionId
                  }
                });

                } else if (status ==  "AUTHENTICATION_SUCCESSFUL") {

                  let responseEnroll = response.authenticationPayerProcess;
                  let form = (document.getElementById('sopFormIframe').contentWindow.document).querySelector('#sopRequestForm');

                  let cavv = null;
                  let eciRaw = null;
                  let xid = null;
                  let ucafCollectionIndicator = null;
                  let ucafAuthenticationData = null;

                  let ecommerceIndicator = null;
                  let directoryServerTransactionId = null;
                  let specificationVersion = null;

                  ecommerceIndicator = parseUndefinedAndNull(responseEnroll.ecommerceIndicator);
                  directoryServerTransactionId = parseUndefinedAndNull(responseEnroll.directoryServerTransactionId);
                  specificationVersion = parseUndefinedAndNull(responseEnroll.specificationVersion);

                  form.querySelector('#ecommerceIndicator').value = ecommerceIndicator;
                  form.querySelector('#directoryServerTransactionId').value = directoryServerTransactionId;
                  form.querySelector('#specificationVersion').value = specificationVersion;

                  if (typeof responseEnroll.visa === 'object') {
                    cavv = parseUndefinedAndNull(responseEnroll.visa.cavv)
                    eciRaw = parseUndefinedAndNull(responseEnroll.visa.eciRaw);
                    xid = parseUndefinedAndNull(responseEnroll.visa.xid);
                  }

                  if (typeof responseEnroll.mastercard === 'object') {
                    ucafCollectionIndicator = parseUndefinedAndNull(responseEnroll.mastercard.ucafCollectionIndicator);
                    ucafAuthenticationData = parseUndefinedAndNull(responseEnroll.mastercard.ucafAuthenticationData);
                  }

                  if ((cavv !== null && eciRaw !== null && xid !== null) ||
                     (ucafCollectionIndicator !== null && ucafAuthenticationData !== null))
                    {
                      if(cardType == "001")
                      {
                        form.querySelector('#cavv').value = cavv;
                        form.querySelector('#eciRaw').value = eciRaw;
                        form.querySelector('#xid').value = xid;
                      }
                      if(cardType == "002")
                      {
                        form.querySelector('#ucafCollectionIndicator').value = ucafCollectionIndicator;
                        form.querySelector('#ucafAuthenticationData').value = ucafAuthenticationData;
                      }

                       transactionProcessor(transaction_uuid, 'ACCEPT', decisionId)
                       .then(responseTransactionProcessor => {
                         let decision = responseTransactionProcessor.response.decision;
                       });

                       setTimeout(() => {
                         onPlaceOrder();
                       }, 500);

                    }
                    else
                    {
                      validatePayer(cardNumber, expirationMonth , expirationYear, transactionId, transaction_uuid)
                        .then(validateResponse => {

                          if (validateResponse.status == "AUTHENTICATION_FAILED")
                          {
                            transactionProcessor(transaction_uuid, 'REJECT', decisionId)
                            .then(responseTransactionProcessor => {
                            });
                            setTimeout(() => {
                              displayInvalidCardError();
                            }, 500);
                          }
                          else {
                            let validate = validateResponse.authenticationPayerProcess;

                            let ecommerceIndicator = null;
                            let directoryServerTransactionId = null;
                            let specificationVersion = null;

                            ecommerceIndicator = parseUndefinedAndNull(validate.ecommerceIndicator);
                            directoryServerTransactionId = parseUndefinedAndNull(validate.directoryServerTransactionId);
                            specificationVersion = parseUndefinedAndNull(validate.specificationVersion);

                            form.querySelector('#ecommerceIndicator').value = ecommerceIndicator;
                            form.querySelector('#directoryServerTransactionId').value = directoryServerTransactionId;
                            form.querySelector('#specificationVersion').value = specificationVersion;

                            if(cardType == "001") {
                              form.querySelector('#cavv').value = validate.visa.cavv;
                              form.querySelector('#eciRaw').value = validate.visa.eciRaw;
                              form.querySelector('#xid').value = validate.visa.xid;
                            }
                            if(cardType == "002")
                            {
                              form.querySelector('#ucafCollectionIndicator').value = validate.mastercard.ucafCollectionIndicator;
                              form.querySelector('#ucafAuthenticationData').value = validate.mastercard.ucafAuthenticationData;
                            }

                            transactionProcessor(transaction_uuid, 'ACCEPT', decisionId)
                            .then(responseTransactionProcessor => {
                              let decision = responseTransactionProcessor.response.decision;
                            });

                            setTimeout(() => {
                              onPlaceOrder();
                            }, 500);

                          }
                        })
                        .catch(validateError => {
                          displayInvalidCardError();
                        });
                    }
                  } else  {
                    transactionProcessor(transaction_uuid, 'REJECT', decisionId)
                    .then(responseTransactionProcessor => {
                    });
                    setTimeout(() => {
                      displayInvalidCardError();
                    }, 500);
                  }
            })
            .catch(authenticationPayerError => {
              transactionProcessor(transaction_uuid, 'REJECT', decisionId)
              .then(responseTransactionProcessor => {
              });
              setTimeout(() => {
                displayInvalidCardError();
              }, 500);
            });
        });

        Cardinal.on('payments.validated', function (data, jwt)
        {
          if(data.ErrorDescription == "Success") {
            validatePayer(cardNumber, expirationMonth , expirationYear, transactionId, transaction_uuid)
              .then(validateResponse => {

                if (validateResponse.status == "AUTHENTICATION_FAILED")
                {
                  transactionProcessor(transaction_uuid, 'REJECT', decisionId)
                   .then(responseTransactionProcessor => {
                   });
                   setTimeout(() => {
                     displayInvalidCardError();
                   }, 500);
                }
                else
                {
                  let validate = validateResponse.authenticationPayerProcess;
                  let form = (document.getElementById('sopFormIframe').contentWindow.document).querySelector('#sopRequestForm');

                  let type = validate.type;
                  let ecommerceIndicator = null;
                  let directoryServerTransactionId = null;
                  let specificationVersion = null;

                  let ucafAuthenticationData = null;
                  let ucafCollectionIndicator = null;

                  let cavv = null;
                  let eciRaw = null;
                  let xid = null;

                  ecommerceIndicator = parseUndefinedAndNull(validate.ecommerceIndicator);
                  directoryServerTransactionId = parseUndefinedAndNull(validate.directoryServerTransactionId);
                  specificationVersion = parseUndefinedAndNull(validate.specificationVersion);

                  form.querySelector('#ecommerceIndicator').value = ecommerceIndicator;
                  form.querySelector('#directoryServerTransactionId').value = directoryServerTransactionId;
                  form.querySelector('#specificationVersion').value = specificationVersion;

                  if(cardType == "001") {
                    form.querySelector('#cavv').value = validate.visa.cavv;
                    form.querySelector('#eciRaw').value = validate.visa.eciRaw;
                    form.querySelector('#xid').value = validate.visa.xid;

                    cavv = validate.visa.cavv;
                    eciRaw = validate.visa.eciRaw;
                    xid = validate.visa.xid;
                  }
                  if(cardType == "002")
                  {
                    form.querySelector('#ucafCollectionIndicator').value = validate.mastercard.ucafCollectionIndicator;
                    form.querySelector('#ucafAuthenticationData').value = validate.mastercard.ucafAuthenticationData;

                    ucafAuthenticationData = validate.mastercard.ucafCollectionIndicator;
                    ucafCollectionIndicator = validate.mastercard.ucafAuthenticationData;
                  }

                  transactionProcessor(transaction_uuid, 'ACCEPT', decisionId)
                  .then(responseTransactionProcessor => {
                    let decision = responseTransactionProcessor.response.decision;
                  });

                  setTimeout(() => {
                    onPlaceOrder();
                  }, 500);
                }
              })
              .catch(authenticationPayerError => {
                transactionProcessor(transaction_uuid, 'REJECT', decisionId)
                .then(responseTransactionProcessor => {
                });
                setTimeout(() => {
                  displayInvalidCardError();
                }, 500);
              });

          } else {
            transactionProcessor(transaction_uuid, 'REJECT', decisionId)
            .then(responseTransactionProcessor => {
             });
             setTimeout(() => {
               displayInvalidCardError();
             }, 500);
          }

          log('payments.validated called with data [%s]',
            JSON.stringify(data, null, '    '));
          });
      })
      .catch(error => {
        console.log(error);
        displayGeneralError();
        return;
      });

    }

    inactiveProductsInCart() {
      const { setCoverageError } = this.props;
      return new Promise((resolve, reject) => {
              fetch( inactiveProductsInCartUrl)
                .then(response => response.json())
                .then(res => {
                   if (res.success) {
                     if (res.response.products !== undefined && res.response.products !== null ) {
                       if (res.response.products.length > 0) {
                         var codes = res.response.products.map(function(x) {
                            return x.code;
                         });
                        let strProducts = codes.join(',');
                        this.displayInactiveProductError(strProducts);
                       } else {
                         resolve(res);
                       }
                     } else {
                       resolve(res);
                     }
                   } else {
                      this.displayGeneralError();
                   }
                });
            });
    }

    checkStock(){
      const { setCoverageError } = this.props;

      return new Promise((resolve, reject) => {
        fetch(checkStockUrl)
          .then(response => response.json())
          .then(res => {
            if (res.success) {
              resolve(res);
            } else {
             store.dispatch(setCoverageError(false, res.message));
            }
          });
      });
    }

    checkCoverage(cp){
      const { setCoverageError, placeOrderHideSpinner } = this.props;
        return new Promise((resolve, reject) => {
          fetch(coverageUrl + cp)
          .then(response => response.json())
          .then(res => {
            if (res.valid) {
              resolve(true);
            } else {
              let errorMessage = this.context.intl.formatMessage({ id: 'checkout.no.coverage.message', description: 'Error coverage' }) ;
              store.dispatch(setCoverageError(false, errorMessage));
            }
          }).catch((err) => {
            const REDIRECT_TIME = 1700;
            setTimeout(function(){
              location.reload();
            }, REDIRECT_TIME);
          });
        });
    }

    onPlaceOrder() {
      const { paymentType, guid, products, placeOrder, hasCheckoutLowerLimitError } = this.props;

      this.setState({
        code: PLACE_ORDER_STATUSES.PLACING_ORDER
      });

      store.dispatch(fetchCartData())
        .then((data) => {

          const limitErrorMessage = this.context.intl.formatMessage({ id: 'cart.summary.lower.limit.error' });

          if(!data.reachedCheckoutLowerLimit){
            hasCheckoutLowerLimitError(data.reachedCheckoutLowerLimit, limitErrorMessage);
            return;
          }
          if (isEmpty(data.paymentInfo) || isEmpty(data.deliveryAddress) || isEmpty(data.deliveryMode)) {
            onPlaceOrderDataReset();
          } else if (!isCartProductsEqual(data.entries, products)) {
            onPlaceOrderCartUpdated();
          } else {
            if (!data.paymentInfo.hasPaymentToken && this.isCardPayment) {
              this.fillPlaceOrderForm(this.prepareCardDetails());
            }
            const errorMessages = {
              'failed.zerovalue.payment' : this.context.intl.formatMessage({ id: 'checkout.order.placement.failed.zerovalue.payment.error' }),
              'stock.reservation' : this.context.intl.formatMessage({ id: 'checkout.order.placement.failed.stock.reservation.error' }),
              'failed.data.reset' : this.context.intl.formatMessage({ id: 'checkout.order.placement.failed.data.reset.error' }),
              'failed.creditCard.payment' : this.context.intl.formatMessage({ id: 'checkout.order.placement.failed.creditCard.error' }),
              'failed.oxxoPay.payment' : this.context.intl.formatMessage({ id: 'checkout.order.placement.failed.oxxoPay.payment.error' }),
              'failed.cashOnDelivery.payment' : this.context.intl.formatMessage({ id:  'checkout.order.placement.failed.cashOnDelivery.payment.error' }),
              'failed.creditCard.infoValidation' : this.context.intl.formatMessage({id : 'checkout.order.placement.failed.creditCard.infoValidation.error'}),
            };

            // Place Order Credit Card
            placeOrder(paymentType, this.placeOrderForm, guid, errorMessages);
          }
        });

    }

    toggleModal() {
      this.setState((prevState) => ({
        isModalOpen: !prevState.isModalOpen
      }), () => {
        if (!mediaQueries.is_large_up()) {
          if (!document.querySelector('html').classList.contains('no-overlay-scroll')) {
            this.setState({
              topOffset: window.pageYOffset
            }, () => topWrapperOffset('open', this.state.topOffset, 'no-overlay-scroll'));
          } else {
            topWrapperOffset('close', this.state.topOffset, 'no-overlay-scroll');
          }
        }
      });

      if (this.state.isModalOpen) {
        onPaymentFail();
      }
    }

    addNewCard() {
      this.setState({
        isCapturingNewCard: true
      });
    }

    toggleUseBillingAddress({ target }) {
      this.setState({
        isNewBillingAddress: target.value === 'new'
      });
    }

    togglePostcodeValidation() {
      const { resetFormData } = this.props;
      this.postcodeFormGroups.each(group => resetFormData(group));

      this.setState(prevState => ({
        showPostcodeValidationForm: !prevState.showPostcodeValidationForm,
        showPostcodeSelectAddress: false
      }));
    }

    togglePostcodeSelectAddress() {
      const { resetFormData } = this.props;
      this.postcodeFormGroups.each(group => resetFormData(group));

      this.setState(prevState => ({
        showPostcodeSelectAddress: !prevState.showPostcodeSelectAddress
      }));
    }

    handlePostcodeContinueClick(stringAddress) {
      const { replaceAddressDetails } = this.props;

      getValidAddressDetails(
        stringAddress,
        replaceAddressDetails,
        this.formGroups,
        this.updateStateCallback
      );

      scrollToEl('.address-form');

      this.togglePostcodeValidation();
      this.togglePostcodeSelectAddress();
    }

    handlePostcodeValidForm(formData) {
      const { country } = this.props.selectedDeliveryAddress;

      const formRequestData = {
        line1: formData.addressLine1,
        postalCode: formData.postalCode,
        country
      };

      validatePostcode(
        formRequestData,
        this.updateStateCallback
      );
    }

    get isCardPayment() {
      const { paymentType } = this.props;
      return paymentType === PAYMENT_METHOD.CARD;
    }

    setTimer() {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }

      this.timerId = setTimeout(
        () => {
          this.setState({ isModalOpen: true });
          this.timerId = null;
        },
        PLACE_ORDER_TIMEOUT
      );
    }

    prepareCardPayment() {
      const { placeOrderHideSpinner } = this.props;
      if (this.placeOrderIFrame) {
        this.placeOrderIFrame.onload = () => {
          if (this.state.code === PLACE_ORDER_STATUSES.PLACING_ORDER) {
            this.setTimer();
          } else {
             this.placeOrderForm = (this.placeOrderIFrame.contentDocument || this.placeOrderIFrame.contentWindow.document).querySelector('#sopRequestForm');

             //Process Payment

             this.continueProcess();

             this.setState({ code: PLACE_ORDER_STATUSES.PLACE_ORDER_READY });
          }
        };
      }
    }

    translateCardType(cardType) {
      return CARD_TYPES[cardType];
    }

    fillPlaceOrderForm({ cardNumber, cardCVN, cardExpiryDate, cardType }) {
      let strCardType = (cardType == "001") ? "VISA" : (cardType == "002") ? "MASTERCARD" : "INVALID";

      this.placeOrderForm.querySelector('#type').value = strCardType;
      this.placeOrderForm.querySelector('#number').value = cardNumber;
      this.placeOrderForm.querySelector('#securityCode').value = cardCVN;
      this.placeOrderForm.querySelector('#expirationMonth').value = cardExpiryDate.split('-')[0] || '';
      this.placeOrderForm.querySelector('#expirationYear').value = cardExpiryDate.split('-')[1] || '';

      this.placeOrderForm.querySelector('#transactionUUID').value = transaction_uuid;

    }

    updateMemberLookup() {
      return new Promise((resolve, reject) => {
        var formData = new FormData();
        formData.append('referralId', this.props.MemberIdLookup.memberId);
        formData.append('deliverByReferralId', String(this.props.MemberIdLookup.deliveryByReferralChecked));

        fetch(updateMemberLookupUrl, {
          method: 'POST',
          body: formData,
        })
        .then(response => response.json())
        .then(res => {
          if (res.success) {
            resolve(true);
          } else {
            let errorMessage = this.context.intl.formatMessage({ id: 'cart.update.error', description: 'cart error' }) ;
            store.dispatch(setCoverageError(false, errorMessage));
          }
        }).catch((err) => {
          const REDIRECT_TIME = 1700;
          setTimeout(function(){
            location.reload();
          }, REDIRECT_TIME);
        });
      });
    }

    prepareCardDetails() {
      const { cardDetails } = this.props;
      const cardNumber = cardDetails.values.cardNumber.replace(/ /g, '');
      const cardCVN = cardDetails.values.securityNumber;
      const cardExpiryDate = `${cardDetails.values.expiryDate.replace('/', '-20')}`;
      const cardType = this.translateCardType(cardDetails.values.cardType);

      return { cardNumber, cardCVN, cardExpiryDate, cardType };
    }

    iframeRefCallback(iframe) {
      this.placeOrderIFrame = iframe;
    }

    iframeRefCallbackSteps(iframe) {
      this.placeOrderIFrameSteps = iframe;
    }

    render() {
      return (
        <WrappedComponent
          onSubmit={this.onSubmit}
          formGroups={this.formGroups}
          billingAddressFormGroups={this.billingAddressFormGroups}
          deliveryAddressFormGroups={this.deliveryAddressFormGroups}
          addNewCard={this.addNewCard}
          toggleUseBillingAddress={this.toggleUseBillingAddress}
          toggleModal={this.toggleModal}
          handleFindAddressClick={this.handleFindAddressClick}
          postcodeFormGroups={this.postcodeFormGroups}
          deliveryPostcodeFormGroups={this.deliveryPostcodeFormGroups}
          handlePostcodeContinueClick={this.handlePostcodeContinueClick}
          togglePostcodeValidation={this.togglePostcodeValidation}
          togglePostcodeSelectAddress={this.togglePostcodeSelectAddress}
          onPostcodeSubmit={this.onPostcodeSubmit}
          onPlaceOrder={this.onPlaceOrder}
          authenticatePaymentProcess={this.authenticatePaymentProcess}
          paymentProcess={this.paymentProcess}
          continueProcess={this.continueProcess}
          fetchDeviceFingerPrint={this.fetchDeviceFingerPrint}
          iFrameRefCallback={this.iframeRefCallback}
          iFrameRefCallbackSteps={this.iframeRefCallbackSteps}
          checkStock={this.checkStock}
          inactiveProductsInCart={this.inactiveProductsInCart}
          checkCoverage={this.checkCoverage}
          generateJwt={this.generateJwt}
          validatePayer={this.validatePayer}
          decision={this.decision}
          transactionProcessor={this.transactionProcessor}
          authenticationPayer={this.authenticationPayer}
          displayInactiveProductError={this.displayInactiveProductError}
          updateMemberLookup={this.updateMemberLookup}
          placeOrderHideSpinner={this.placeOrderHideSpinner}
          {...this.state}
          {...this.props}
        />
      );
    }
  }

  CheckoutPayment.contextTypes = {
    intl: PropTypes.object.isRequired
  };

  CheckoutPayment.propTypes = {
    triggerValidate: PropTypes.func.isRequired,
    resetFormData: PropTypes.func.isRequired,
    submitRegistration: PropTypes.func.isRequired,
    setPaymentInfo: PropTypes.func.isRequired,
    getCheckoutReviewData: PropTypes.func.isRequired,
    replaceAddressDetails: PropTypes.func.isRequired,
    replaceBillingAddress: PropTypes.func.isRequired,
    resetBillingAddress: PropTypes.func.isRequired,
    selectedDeliveryAddress: PropTypes.object.isRequired,
    cardDetailsMountTrigger: PropTypes.func.isRequired,
    guid: PropTypes.string.isRequired,
    products: PropTypes.any.isRequired,
    paymentType: PropTypes.string.isRequired,
    setDeviceFingerPrint: PropTypes.func.isRequired,
    fetchDeviceFingerPrint: PropTypes.func.isRequired,
    validatePayer: PropTypes.func,
    authenticationPayer: PropTypes.func,
    displayGeneralError: PropTypes.func,
    displayInactiveProductError: PropTypes.func,
    displayInvalidCardError: PropTypes.func,
    MemberIdLookup: PropTypes.object.isRequired,
    updateMemberLookup: PropTypes.func,
    placeOrderHideSpinner: PropTypes.func.isRequired
  };
  return CheckoutPayment;
};

export default CheckoutPaymentHOC;
