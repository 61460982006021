import { setServerMsg } from 'app/globalMessages/globalMessagesActions';
import * as MSG from 'app/globalMessages/globalMessagesConstants';

const { accountLoginUrl } = window.inlineGlobalConfig;

const genericErrorHandler = (dispatch, statusCode) => {
  const unauthorized = 401;
  const forbidden = 403;

  switch (statusCode) {
    case unauthorized:
    case forbidden:
      window.location = accountLoginUrl;
      break;
    default:
      window.scrollTo(0, 0);
      dispatch(setServerMsg(MSG.DEFAULT_ERROR_MSG, MSG.TYPE_ERROR, true));
      break;
  }
};

export default genericErrorHandler;
