import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import GuestAddressForm from '../../../formComponents/guestAddressForm/guestAddressForm';
import checkoutGenericContainer from '../checkoutGeneric/checkoutGenericContainer';
import checkoutGenericHOC from '../checkoutGeneric/checkoutGenericHOC';
import Guest from '../guest/guestContainer';

const { registrationValidation, enableDirectDelivery } = window.inlineCheckout;

class CashOnDeliveryPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmPasswordInputInUse: false,
      inputsInUse: new Set()
    }

    this.sectionInputInUseHandler = this.sectionInputInUseHandler.bind(this);
    this.isInputInUse = this.isInputInUse.bind(this);
    this.inputInUseHandler = this.inputInUseHandler.bind(this);
  }

  componentDidMount() {
    const { cashOnDeliveryDetailsMountTrigger, selectedDeliveryMode, getDisplayCashOnDelivery } = this.props;
    cashOnDeliveryDetailsMountTrigger(selectedDeliveryMode);
    getDisplayCashOnDelivery();
  }

  sectionInputInUseHandler(event) {
    event.preventDefault();
    let inputStateHandler;

    if (event.target.value.length > 0) {
      inputStateHandler = true;
    } else {
      inputStateHandler = false;
    }

    this.setState({
      confirmPasswordInputInUse: inputStateHandler,
    });
  }

  isInputInUse(inputName) {
    return this.state.inputsInUse.has(inputName)
  }

  inputInUseHandler(event, inputName) {
    event.preventDefault();

    const {
      inputsInUse
    } = this.state;

    if (event.target.value.length > 0) {
      inputsInUse.add(inputName);
    } else {
      inputsInUse.delete(inputName);
    }
    this.setState({
      inputsInUse
    });
  }

  render() {
    const { getCashOnDeliveryInfo, onSubmitPayment, isCashOnDeliveryEnabled, isLoggedIn, formGroups, isPriceOverTwoHundred, disableSubmit, isGuest, deliveryAddressFormGroups, postcodeFormGroups } = this.props;
    const handlersAndValidation = {
      handleValidForm: formGroups.resolve,
      handleInvalidForm: formGroups.reject,
      validation: registrationValidation
    };

    return (
      <div>
      {/* { !enableDirectDelivery && */}
        <div className="checkout-section__cashondelivery">
          <div className="checkout-section__cashondelivery-msg">
            {
              isCashOnDeliveryEnabled
                ? <FormattedHTMLMessage id="checkout.billing.cashOnDelivery.message" description="Cash On Delivery message" />
                :
                <div>
                  <h1 className="checkout-section__cashondelivery__title">
                    <FormattedHTMLMessage id="checkout.billing.unavailable.method" description="Cash On Delivery message" />
                  </h1>
                  <ul>
                    <li>
                      <FormattedMessage id="checkout.billing.cashOnDelivery.message.invalid.one" description="Cash On Delivery message" />
                    </li>
                    <li>
                      <FormattedHTMLMessage id="checkout.billing.cashOnDelivery.message.invalid.two" description="Cash On Delivery message" />
                      {' '}
                      <span className="lato">
                        <FormattedHTMLMessage id="checkout.billing.cashOnDelivery.message.invalid.amount" description="Cash On Delivery message" />
                      </span>
                    </li>
                  </ul>
                </div>
            }
          </div>
          {
            !isLoggedIn && !isGuest &&
            <div className="row">
              <div className="columns large-6 medium-6 small-12">
                <span>Para poder rastrear o hacer una futura compra, por favor crea tu cuenta</span>
              </div>
              <div className="columns large-6 medium-6 small-12 register">
                <a className="btn-sendRegister" href="./checkout/auth/register">Regístrarme</a>
              </div>
            </div>
          }
          { isGuest && isCashOnDeliveryEnabled && isPriceOverTwoHundred &&
            <GuestAddressForm
              formGroups={deliveryAddressFormGroups}
              hasReference={false}
              isInputInUse={this.isInputInUse}
              inputInUseHandler={this.inputInUseHandler}
              // Postcode lookup related properties
              showPostcodeValidationForm={false}
              showPostcodeTryAnotherMsg={false}
              postcodeFormGroups={postcodeFormGroups}
            />
          }
          {
            (isLoggedIn || isGuest) &&
            <button
              id="btn-submitPayment"
              className={(!isCashOnDeliveryEnabled || disableSubmit || !isPriceOverTwoHundred || (this.props.MemberIdLookup.deliveryByReferralChecked && (this.props.MemberIdLookup.isError || this.props.MemberIdLookup.memberId === null || this.props.MemberIdLookup.memberId === undefined || this.props.MemberIdLookup.memberId === '')) ) ? "auth-form__register-btn-inside-disabled" : "auth-form__register-btn-inside"}
              onClick={(e) => onSubmitPayment(e, getCashOnDeliveryInfo)}
              disabled={!isCashOnDeliveryEnabled || disableSubmit || !isPriceOverTwoHundred || (this.props.MemberIdLookup.deliveryByReferralChecked && (this.props.MemberIdLookup.isError || this.props.MemberIdLookup.memberId === null || this.props.MemberIdLookup.memberId === undefined || this.props.MemberIdLookup.memberId === ''))}
            >
              <div className="sending-payment">
                <FormattedMessage id="checkout.billing.finalize.purchase.message" description="Change" />
              </div>
            </button>
          }
          {
            !isLoggedIn && !isGuest &&
            <Guest />
          }
        </div>
      {/* // } */}
      </div>
    );
  }
}

CashOnDeliveryPayment.propTypes = {
  getCashOnDeliveryInfo: PropTypes.func.isRequired,
  onSubmitPayment: PropTypes.func.isRequired,
  cashOnDeliveryDetailsMountTrigger: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  formGroups: PropTypes.object.isRequired,
  isPriceOverTwoHundred: PropTypes.bool,
  disableSubmit: PropTypes.bool.isRequired,
};

CashOnDeliveryPayment.contextTypes = {
  intl: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  isPriceOverTwoHundred: state.Global.isPriceOverTwoHundred,
});

export default connect(mapStateToProps)(checkoutGenericContainer(checkoutGenericHOC(CashOnDeliveryPayment)));

