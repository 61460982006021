import { connect } from 'react-redux';
import CashOnDeliveryPayment from './cashOnDeliveryPayment';
import { getCashOnDeliveryInfo, getDisplayCashOnDelivery } from './cashOnDeliveryPaymentActions';
import { CASH_ON_DELIVERY_PAYMENT_MOUNT } from './cashOnDeliveryConstants';
import { PAYMENT_METHOD } from '../checkoutPayment/checkoutPaymentConstants';
import checkoutGenericContainer from '../checkoutGeneric/checkoutGenericContainer';
import checkoutGenericHOC from '../checkoutGeneric/checkoutGenericHOC';

const mapStateToProps = (state) => ({
  MemberIdLookup: state.MemberIdLookup,
  selectedDeliveryMode: state.Global.cartData.deliveryMode.name,
  isCashOnDeliveryEnabled: state.Global.isCashOnDeliveryEnabled,
  paymentType: PAYMENT_METHOD.CASHONDELIVERY
});

const mapDispatchToProps = (dispatch) => ({
  getCashOnDeliveryInfo() {
    dispatch(getCashOnDeliveryInfo());
  },
  cashOnDeliveryDetailsMountTrigger(deliveryMethod) {
    dispatch({ type: CASH_ON_DELIVERY_PAYMENT_MOUNT, deliveryMethod });
  },
  getDisplayCashOnDelivery() {
    dispatch(getDisplayCashOnDelivery());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CashOnDeliveryPayment);
